import React from "react";
import { IconButton } from "@mui/material";
import { toast } from "react-hot-toast";
import ClipBoard from "react-copy-to-clipboard";

const CopyToClipboard = ({ value }) => {
  return (
    <ClipBoard
      text={value}
      options={{
        format: "text/plain",
      }}
      onCopy={() => {
        toast.success("Copied to clipboard!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }}
    >
      <IconButton>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.8006 3.93202L8.6726 3.93202C9.28526 3.93537 9.87148 4.18196 10.3023 4.61755C10.7332 5.05313 10.9733 5.64203 10.9699 6.25468L10.9699 9.07602C10.9733 9.68867 10.7332 10.2776 10.3023 10.7132C9.87148 11.1487 9.28526 11.3953 8.6726 11.3987H5.8006C5.18795 11.3953 4.60172 11.1487 4.17089 10.7132C3.74005 10.2776 3.4999 9.68867 3.50327 9.07602L3.50327 6.25535C3.49972 5.64258 3.73979 5.05351 4.17065 4.61778C4.6015 4.18205 5.18783 3.93537 5.8006 3.93202Z"
            stroke="#FF3A8A"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.6146 13.2654H9.7946C11.4937 13.246 12.8555 11.8531 12.8366 10.154V7.04271"
            stroke="#FF3A8A"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </IconButton>
    </ClipBoard>
  );
};

export default CopyToClipboard;
