/**
 * use yup schema to validator object which contains the error messages
 * author Khaled Mofeed
 */
import * as yup from "yup";
export default yup.object().shape({
  old_password: yup.string(),
  password: yup
    .string()
    .when("old_password", (old_password, new_password) =>
      old_password
        ? new_password
            .min(8, "The password must be at least 8 characters.")
            .required("New password is required")
        : new_password
    ),
  password_confirmation: yup
    .string()
    .when("old_password", (old_password, password_confirmation) =>
      old_password && yup.ref("password")
        ? password_confirmation.oneOf(
            [yup.ref("password"), null],
            "The password confirmation doesn't match the password"
          )
        : password_confirmation
    ),
});
