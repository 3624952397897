import React from "react";
import ForgotPassword from "../../components/ForgotPassword";
function index() {
  return (
    <div>
      <ForgotPassword />
    </div>
  );
}

export default index;
