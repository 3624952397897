import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../../../../scripts/context";
import { useForm } from "../../../../Layout/forms/actions";
import { Spinner } from "react-bootstrap";

function InfoForm(props) {
  // InfoForm component, right side of edit profile page, that user needs to modify his data
  const { userData } = useContext(AppContext);
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { form, handelUpdateProfileSubmit, loading, setValue, errors } =
    useForm({
      name: userData?.name || "",
      bio: userData?.bio || "",
      custom_url: userData?.custom_url || "",
      twitter: userData?.twitter || "",
      facebook: userData?.facebook || "",
      portfolio: userData?.portfolio || "",
      linkedin: userData?.linkedin || "",
      instagram: userData?.instagram || "",
    });

  /**
   * extract the attributes of form
   * author Khaled Mofeed
   */
  const {
    name,
    bio,
    custom_url,
    twitter,
    facebook,
    portfolio,
    linkedin,
    instagram,
  } = form;

  return (
    <Row>
      <Col md={12}>
        <h6 className="nickName mt-4 mb-4">{userData?.name}</h6>
      </Col>
      <Col
        md={12}
        className="justify-content-center align-items-center bg-border-gradiant contact-form-bg editProfile"
      >
        <form
          className="form row position-relative"
          onSubmit={handelUpdateProfileSubmit}
        >
          <div className="form-group col-12 col-md-12">
            <label for="basic-url">Display name</label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.name && "is-invalid"
              }`}
              name="name"
              placeholder="Enter your display name"
              id="id_name"
              value={name}
              onChange={setValue}
            />
            {errors.name && (
              <div className="invalid-feedback" id="last_name">
                {errors.name}
              </div>
            )}
          </div>
          <div className="form-group col-12 col-md-12">
            <label for="basic-url">Custom url</label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.custom_url && "is-invalid"
              }`}
              name="custom_url"
              placeholder="Enter your custom URL"
              id="id_custom_url"
              value={custom_url}
              onChange={setValue}
            />
            {errors.custom_url && (
              <div className="invalid-feedback" id="last_name">
                {errors.custom_url}
              </div>
            )}
          </div>
          <div className="form-group col-12 col-md-12">
            <label for="basic-url">Bio</label>
            <textarea
              name="bio"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.bio && "is-invalid"
              }`}
              rows="3"
              placeholder="About yourselt in a few words"
              id="id_bio"
              value={bio}
              onChange={setValue}
            ></textarea>
            {errors.bio && (
              <div className="invalid-feedback" id="last_name">
                {errors.bio}
              </div>
            )}
          </div>
          <h6 className="nickName mt-2 mb-4">Social</h6>
          <div className="form-group col-6 col-md-6">
            <label className="basic-url">
              <img
                src={`${process.env.REACT_APP_ASSETS}/img/twitter.svg`}
                alt="twitter"
                className="twetterLinkInput"
              />
              twitter
            </label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.twitter && "is-invalid"
              }`}
              name="twitter"
              id="id_nameSocial"
              value={twitter}
              onChange={setValue}
            />
            {errors.twitter && (
              <div className="invalid-feedback" id="last_name">
                {errors.twitter}
              </div>
            )}
          </div>
          <div className="form-group col-6 col-md-6">
            <label className="basic-url">
              <img
                src={`${process.env.REACT_APP_ASSETS}/img/facebok.svg`}
                alt="facebook"
                className="twetterLinkInput"
              />
              facebook
            </label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.facebook && "is-invalid"
              }`}
              name="facebook"
              id="id_nameSocial"
              value={facebook}
              onChange={setValue}
            />
            {errors.facebook && (
              <div className="invalid-feedback" id="last_name">
                {errors.facebook}
              </div>
            )}
          </div>
          <div className="form-group col-6 col-md-6">
            <label className="basic-url">
              <img
                src={`${process.env.REACT_APP_ASSETS}/img/portfolio.svg`}
                alt="portfolio"
                className="twetterLinkInput"
              />
              portfolio
            </label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.portfolio && "is-invalid"
              }`}
              name="portfolio"
              id="id_nameSocial"
              value={portfolio}
              onChange={setValue}
            />
            {errors.portfolio && (
              <div className="invalid-feedback" id="last_name">
                {errors.portfolio}
              </div>
            )}
          </div>
          <div className="form-group col-6 col-md-6">
            <label className="basic-url">
              <img
                src={`${process.env.REACT_APP_ASSETS}/img/linkedin.svg`}
                alt="linkedin"
                className="twetterLinkInput"
              />
              linkedin
            </label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.linkedin && "is-invalid"
              }`}
              name="linkedin"
              id="id_nameSocial"
              value={linkedin}
              onChange={setValue}
            />
            {errors.linkedin && (
              <div className="invalid-feedback" id="last_name">
                {errors.linkedin}
              </div>
            )}
          </div>

          <div className="form-group col-6 col-md-6">
            <label for="basic-url">
              <img
                 src={`${process.env.REACT_APP_ASSETS}/img/instagram.svg`}
                className="twetterLinkInput"
                alt="instgram"
              />
              Instagram
            </label>
            <input
              type="text"
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                errors.instagram && "is-invalid"
              }`}
              name="instagram"
              id="id_nameGetTouchInstagram"
              value={instagram}
              onChange={setValue}
            />
            {errors.instagram && (
              <div className="invalid-feedback" id="last_name">
                {errors.instagram}
              </div>
            )}
          </div>

          <p className="descriptionLable border-bottom pb-4 mb-4">
            To update your settings you should sign message through your wallet.
            Click 'Update profile' then sign the message
          </p>
          <div className="col-12 col-md-12">
            <button
              id="info__submitUpdate"
              className="btn btn-primary mr-2"
              type="submit"
              name="submit"
              value="Submit"
              disabled={loading}
            >
              Save Changes
              {loading && (
                <Spinner
                  style={{ marginLeft: ".5rem" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </form>
      </Col>
    </Row>
  );
}

export default InfoForm;
