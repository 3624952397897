import toast from "react-hot-toast";
import { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../plugins/axios";
import { endpoints } from "../../../utils/networks";

const initialContext = {
  user: null,
  roles_permissions: [],
  permissions: [],
  setState: () => {},
  logout: () => {},
  loading: true,
};
const AppContext = createContext(initialContext);
export const AuthAdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, setStates] = useState(initialContext);
  const TOKEN = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const setState = (object) => {
    setStates((prevState) => ({ ...prevState, ...object }));
  };

  const getInitData = async () => {
    let userData;
    setLoading(true);
    try {
      const { data } = await request(
        endpoints.Profile.GetUserData.method,
        endpoints.Profile.GetUserData.url
      );
      userData = data.user;

      setState({
        user: userData,
      });
      setLoading(false);
    } catch (error) {
      navigate("/back-office/login", { replace: true });
      localStorage.removeItem("token");
      setLoading(false);
      toast.error(error.response.data.message);
    }

    try {
      const roles_and_permissions = await Promise.all([
        userData?.permissions.includes("view_system_role")
          ? request(
              endpoints.Roles.GetRoleList.method,
              endpoints.Roles.GetRoleList.url,
              {
                params: {
                  getAll: 1,
                },
              }
            )
          : Promise.resolve(null),

        request(
          endpoints.Roles.GetAllPermissions.method,
          endpoints.Roles.GetAllPermissions.url
        ),
      ]);
      setState({
        user: userData,
        roles_permissions: roles_and_permissions[0].data.data.data,
        permissions: roles_and_permissions[1].data.data,
      });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const logout = async () => {
    try {
      await request(endpoints.auth.Logout.method, endpoints.auth.Logout.url);
      navigate("/back-office/login");
      setShouldUpdate(true);
      localStorage.removeItem("token");
      setStates(initialContext);
      toast.success("Successfully logged out");
      document.body.classList.remove("admin");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Unauthenticated.");
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
    return () => setShouldUpdate(false);
  }, [shouldUpdate]);

  useEffect(() => {
    if (TOKEN) {
      getInitData();
    }
  }, []);

  useEffect(() => {
    if (TOKEN) {
      getInitData();
    }
  }, [shouldUpdate]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        logout,
        setState,
        setShouldUpdate,
        TOKEN,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AppContext);
};
