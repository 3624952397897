import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./style.css";
import { advisorsDataConfig } from "../../../configs/configAdvisors";

function Advisors(props) {
  /*  define the data from  advisors Data Config | khaled Mofeed | 29/10/2022 */
  const advisorsData = advisorsDataConfig;
  return (
    <Row className="justify-content-center align-items-center min-height-100per Advisors pb-0">
      <Col md={10} xl={9}>
        <Row className="justify-content-center">
          <Col md={10} xl={9}>
            <div className="title-div">
              <h2 id="id_titleAdvisors">Advisors</h2>
            </div>
          </Col>
        </Row>
        <Row className="content-advisory justify-content-center">
          <Col md={10} xl={10}>
            <Row>
              {advisorsData.map((cardAdvisors) => (
                <Col lg={4} key={cardAdvisors.id}>
                  <div className="card-advisor">
                    <div className="img-info">
                      <img
                        src={cardAdvisors.Image}
                        alt="advisor"
                        id="id_imgAdvisors"
                      />
                      <div className="info-card">
                        <h4 id="id_nameAdvisors">{cardAdvisors.name}</h4>
                        <p id="id_jobAdvisors">{cardAdvisors.job}</p>
                      </div>
                    </div>

                    <span id="id_discribtionAdvisors">
                      {cardAdvisors.firstcontent}
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Advisors;
