import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Image } from 'react-bootstrap';
import Hexagon from 'react-hexagon';
import './style.css';

const ImageUploader = ({ src = '', file, handleChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        alignItems: 'center',
        gap: '1rem',
      }}>
      
            <Hexagon
            className="svgOption"
              style={{ width: '200px', height: '200px', objectFit: 'cover', stroke : 'none' }}
              backgroundImage={file ? URL.createObjectURL(file) : src}
              // href={URL.createObjectURL(file)}
            />
            {/* <img
            className='w-25 d-none'
             
              src={URL.createObjectURL(file)}
              alt='uploaded'
            /> */}
      <Button
        variant='contained'
        size='small'
        component='label'
        style={{
          width: 'fit-content',
        }}>
        edit
        <input hidden accept='image/*' multiple type='file' onChange={handleChange} />
      </Button>
    </div>
  );
};

export default ImageUploader;
