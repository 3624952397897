import React, { useRef, useEffect, useContext } from "react";
import * as d3 from "d3";

import { useNavigate } from "react-router-dom";

import { AppContext } from "../../../../../scripts/context";
import {
  config_Parents_Sections,
  config_Title_Sections,
} from "../../../../../configs/map/config_Parents_Sections";
import { GetLocalStorag, SaveLocalStorag } from "../../../../../Helper/Helper";
function ContainerMap(IdSelected) {
  const {
    Nft_A_Section,
    Nft_B_Section,
    Nft_C_Section,
    Nft_D_Section,
    Nft_E_Section,
    Nft_F_Section,
    Nft_G_Section,
  } = useContext(AppContext);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  // function used for Handle The Click Event  || Khaled Mofed
  const handleNavigateToLandProfile = (uuid) => {
    navigate(`/land/${uuid}`);
  };
  useEffect(() => {
    /** The formula for measuring the area of ​​a hexagon || author Khaled Mofeed */
    const _s32 = Math.sqrt(3) / 2;
    /** height of container Map || author Khaled Mofeed */
    const height = 600;
    /** Container Of Map || author Khaled Mofeed */
    const svgContainer = d3
      .select(mapRef.current)
      .append("svg")
      .attr("class", "border svgStyle")
      .attr("width", "100%")
      .attr("height", height)
      .append("g");
    /** Add Zoom Effect To Map || author Khaled Mofeed */
    const zoom = d3.zoom().scaleExtent([1, 7]).on("zoom", handleZoom);
    svgContainer.call(zoom);
    /** Handle Nft image in hexagone || khaled Mofed */
    const defs = d3.select("svg").append("defs");
    /** Handle Zoom Map || khaled Mofed */
    function handleZoom(e) {
      d3.selectAll("path").attr("transform", e.transform);
    }

    //**************************************************
    //******* Parents_Sections  || Khaled Mofed ********
    //**************************************************/
    config_Parents_Sections?.map((Parents_Sections, index) => {
      const hexagon1Parent8 = svgContainer
        .selectAll("path.area")
        .data([
          generateHexagon(
            ...(Parents_Sections?.location?.split(",").map(Number) || [])
          ),
        ])
        .enter()
        .append("path")
        .style("stroke", `${Parents_Sections?.stroke}`)
        .style("fill", `${Parents_Sections?.fill || "#ff7bb0"}`)
        .attr("d", d3.line());
    });
    //****************************************************
    //***** Childs_Sections A,B,C,D,E,F,G || Khaled Mofeed
    //****************************************************/
    config_Title_Sections?.map((Title_Sections, index) => {
      /** get the parents and sub Parents of child for map || khaled Mofed */
      const _SectionMap_Parent = Title_Sections?.filter((obj) => {
        const firstDigit = obj.locationMap.split(",")[0].trim();
        return firstDigit === "100" || firstDigit === "33";
      });
      /** get the Child Without parents and sub Parents for map || khaled Mofed */
      const _SectionMap = Title_Sections?.filter((obj) => {
        const firstDigit = obj.locationMap.split(",")[0].trim();
        return !(firstDigit === "100" || firstDigit === "33");
      });
      /** render the data of child by sections || khaled Mofed */
      const _SectionData = _SectionMap.map((obj, index) => {
        const _Nft_Section = Title_Sections[0].section;
        /** A variable used to check for the type of a session || khaled Mofed */
        const Any_Nft_Section =
          (_Nft_Section === "A" && Nft_A_Section) ||
          (_Nft_Section === "B" && Nft_B_Section) ||
          (_Nft_Section === "C" && Nft_C_Section) ||
          (_Nft_Section === "D" && Nft_D_Section) ||
          (_Nft_Section === "E" && Nft_E_Section) ||
          (_Nft_Section === "F" && Nft_F_Section) ||
          (_Nft_Section === "G" && Nft_G_Section);
        const matchingUser = Any_Nft_Section[index];
        if (matchingUser) {
          return {
            ...obj,
            ...matchingUser,
          };
        }
        return obj;
      });
      /** render the data of Parent by sections || khaled Mofed */
      _SectionMap_Parent.map((_sectionParent, index) => {
        const hexagon_Parent = svgContainer
          .selectAll("path.area")
          .data([
            generateHexagon(
              ...(_sectionParent?.locationMap?.split(",").map(Number) || [])
            ),
          ])
          .enter()
          .append("path")
          .style("stroke", `${_sectionParent?.stroke}`)
          .style("fill", `${_sectionParent?.fill || "#ff7bb0"}`)
          .attr("d", d3.line());
      });
      /** render the data of NFT || khaled Mofed */

      _SectionData?.map((_section, index) => {
        /** append the image Nft by Svg if it exists|| khaled Mofed */
        defs
          .append("svg:pattern")
          .attr("id", `${_section?.image}`)
          .attr("width", 25)
          .attr("height", 23)
          .append("svg:image")
          .attr("xlink:href", `${_section?.image}`)
          .attr("width", 25)
          .attr("height", 23)
          .attr("x", "-2")
          .attr("y", "-2");
        const isStrock = _section?.stroke;
        const isFill = _section?.fill;
        const unClick = _section?.unClick;
        const haveImage = _section?.image;
        const selectedNft = IdSelected === _section?.uuid;
        const hexagon_Child = svgContainer
          .selectAll("path.area")
          .data([
            generateHexagon(
              ...(_section?.locationMap?.split(",").map(Number) || [])
            ),
          ])
          .enter()
          .append("path")
          .attr(
            "class",
            !isStrock && !isFill
              ? haveImage
                ? "HexaWithImage"
                : "hexagon"
              : haveImage
              ? "HexaWithImage"
              : ""
          )
          .style("stroke", `${_section?.stroke}`)
          .style(
            "fill",
            haveImage
              ? function (d) {
                  return `url(#${_section?.image})`;
                }
              : `${_section?.fill || "#ff7bb0"}`
          )
          .attr("data-id", _section?.uuid)
          .on("click", function () {
            if (!unClick) {
              // Remove IdSelected class from all hexagons || khaled Mofed */
              d3.selectAll(".hexagon, .HexaWithImage").classed(
                "IdSelected",
                false
              );
              // Add IdSelected class to the selected hexagon || khaled Mofed */
              d3.select(this).classed("IdSelected", true);
              // Save the ID of the selected hexagon in localStorage || khaled Mofed */
              SaveLocalStorag("selectedHexagonId", _section?.uuid);
              handleNavigateToLandProfile(_section?.uuid);
            }
          })
          .attr("d", d3.line());
      });
    });

    const selectedHexagonId = GetLocalStorag("selectedHexagonId");
    // Apply the IdSelected class to the selected hexagon || khaled Mofed */
    if (selectedHexagonId) {
      svgContainer
        .select(`path[data-id="${selectedHexagonId}"]`)
        .classed("IdSelected", true);
    }
    // function used for Generate Hexagon Shape  || Khaled Mofed
    function generateHexagon(size, xDiff, yDiff) {
      return [
        [size + xDiff, 0 + yDiff], // Center Right
        [size / 2 + xDiff, size * _s32 + yDiff], // Bottom Right
        [-size / 2 + xDiff, size * _s32 + yDiff], // Bottom Left
        [-size + xDiff, 0 + yDiff], // Center Left
        [-size / 2 + xDiff, -size * _s32 + yDiff], // Top left
        [size / 2 + xDiff, -size * _s32 + yDiff], // Top Right
      ];
    }

    return () => svgContainer.on("click", null);
  }, []);

  return (
    <div className="col-12 ">
      <div className="MapContainer ">
        <svg ref={mapRef} width="100%" height="100%">
          <path className="hexagon" />
        </svg>
      </div>
    </div>
  );
}

export default ContainerMap;
