export const config_F_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa6",
    locationMap: "100, 600, 475",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
    section: "E",
  },
  // 1 top parent 1
  {
    nameMap: "subParentBottomCenter",
    locationMap: "33, 600, 475",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent1",
    locationMap: "10.7, 600, 455.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent1",
    locationMap: "10.7, 583.5, 465.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent1",
    locationMap: "10.7, 583.5, 484.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent1",
    locationMap: "10.7, 600, 494",
  },
  {
    nameMap: "Child5_Buttom_SubParent1",
    locationMap: "10.7, 616.4, 484.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent1",
    locationMap: "10.7, 616.4, 465.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent1",
    locationMap: "10.7, 600, 475",
  },
  // 2 top left parent 2
  {
    nameMap: "subParent7TopContainer",
    locationMap: "33, 600, 417",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent7",
    locationMap: "10.7, 600, 397.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent7",
    locationMap: "10.7, 583.5, 407.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent7",
    locationMap: "10.7, 583.5, 426.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent7",
    locationMap: "10.7, 600, 436",
  },
  {
    nameMap: "Child5_Buttom_SubParent7",
    locationMap: "10.7, 616.4, 426.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent7",
    locationMap: "10.7, 616.4, 407.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent7",
    locationMap: "10.7, 600, 417",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParent6RightContainer",
    locationMap: "33, 650, 446",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent6",
    locationMap: "10.7, 650, 426.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent6",
    locationMap: "10.7, 633.5, 436.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent6",
    locationMap: "10.7, 633.5, 455.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent6",
    locationMap: "10.7, 650, 465",
  },
  {
    nameMap: "Child5_Buttom_SubParent6",
    locationMap: "10.7, 666.4, 455.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent6",
    locationMap: "10.7, 666.4, 436.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent6",
    locationMap: "10.7, 650, 446",
  },

  // 4 bottom parent 4
  {
    nameMap: "subParent5BottomContainer",
    locationMap: "33, 650, 504",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent5",
    locationMap: "10.7, 650, 484.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent5",
    locationMap: "10.7, 633.5, 494.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent5",
    locationMap: "10.7, 633.5, 513.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent5",
    locationMap: "10.7, 650, 523",
  },
  {
    nameMap: "Child5_Buttom_SubParent5",
    locationMap: "10.7, 666.4, 513.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent5",
    locationMap: "10.7, 666.4, 494.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent5",
    locationMap: "10.7, 650, 504",
  },

  //  5 bottom right parent 5
  {
    nameMap: "subParent4BottomCenterContainer",
    locationMap: "33, 600, 533",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent4",
    locationMap: "10.7, 600, 513.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent4",
    locationMap: "10.7, 583.5, 523.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent4",
    locationMap: "10.7, 583.5, 542.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent4",
    locationMap: "10.7, 600, 552",
  },
  {
    nameMap: "Child5_Buttom_SubParent4",
    locationMap: "10.7, 616.4, 542.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent4",
    locationMap: "10.7, 616.4, 523.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent4",
    locationMap: "10.7, 600, 533",
  },
  //  6 top right parent 6
  {
    nameMap: "subParent3BottomLeftContainer",
    locationMap: "33, 550, 504",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent3",
    locationMap: "10.7, 550, 484.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent3",
    locationMap: "10.7, 533.5, 494.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent3",
    locationMap: "10.7, 533.5, 513.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent3",
    locationMap: "10.7, 550, 523",
  },
  {
    nameMap: "Child5_Buttom_SubParent3",
    locationMap: "10.7, 566.4, 513.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent3",
    locationMap: "10.7, 566.4, 494.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent3",
    locationMap: "10.7, 550, 504",
  },
  //  7 top right parent 7
  {
    nameMap: "subParent2TopLeftContainer",
    locationMap: "33, 550, 446",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_SubParent2",
    locationMap: "10.7, 550, 426.8",
  },
  {
    nameMap: "Child2_Buttom_SubParent2",
    locationMap: "10.7, 533.5, 436.2",
  },
  {
    nameMap: "Child3_Buttom_SubParent2",
    locationMap: "10.7, 533.5, 455.3",
  },
  {
    nameMap: "Child4_Buttom_SubParent2",
    locationMap: "10.7, 550, 465",
  },
  {
    nameMap: "Child5_Buttom_SubParent2",
    locationMap: "10.7, 566.4, 455.3",
  },
  {
    nameMap: "Child6_Buttom_SubParent2",
    locationMap: "10.7, 566.4, 436.2",
  },
  {
    nameMap: "Child7_Buttom_SubParent2",
    locationMap: "10.7, 550, 446",
  },
];
