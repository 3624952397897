import axios from "axios";
import { config } from "../../configs/axios";

/* Create a new axios instance | khaled Mofeed | 12/12/2022 */
const axiosInstance = axios.create(config);
/* this function handles all axois requests
/* param method 
/* param url
/* param payload
/* returns {*}
/* | khaled Mofeed | 12/12/2022 */

export const request = (method, url, payload = {}) => {
  return axiosInstance[method](url, payload);
};
export default axiosInstance;
