export const config_G_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa5",
    locationMap: "100, 752, 388",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
    section: "F",
  },
  // 1 top parent 1
  {
    nameMap: "subParentBottomRight",
    locationMap: "33, 752, 388",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent1",
    locationMap: "10.7, 752, 368.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent1",
    locationMap: "10.7, 735.5, 378.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent1",
    locationMap: "10.7, 735.5, 397.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent1",
    locationMap: "10.7, 752, 407",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent1",
    locationMap: "10.7, 768.4, 397.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent1",
    locationMap: "10.7, 768.4, 378.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent1",
    locationMap: "10.7, 752, 388",
  },
  // 2 top left parent 2
  {
    nameMap: "subParent7TopBottomRightContainer",
    locationMap: "33, 752, 330",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent7",
    locationMap: "10.7, 752, 310.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent7",
    locationMap: "10.7, 735.5, 320.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent7",
    locationMap: "10.7, 735.5, 339.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent7",
    locationMap: "10.7, 752, 349",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent7",
    locationMap: "10.7, 768.4, 339.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent7",
    locationMap: "10.7, 768.4, 320.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent7",
    locationMap: "10.7, 752, 330",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParent6RightBottomRightContainer",
    locationMap: "33, 802, 359",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent6",
    locationMap: "10.7, 802, 339.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent6",
    locationMap: "10.7, 785.5, 349.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent6",
    locationMap: "10.7, 785.5, 368.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent6",
    locationMap: "10.7, 802, 378",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent6",
    locationMap: "10.7, 818.4, 368.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent6",
    locationMap: "10.7, 818.4, 349.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent6",
    locationMap: "10.7, 802, 359",
  },

  // 4 bottom parent 4
  {
    nameMap: "subParent5BottomBottomRightContainer",
    locationMap: "33, 802, 417",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent5",
    locationMap: "10.7, 802, 397.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent5",
    locationMap: "10.7, 785.5, 407.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent5",
    locationMap: "10.7, 785.5, 426.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent5",
    locationMap: "10.7, 802, 436",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent5",
    locationMap: "10.7, 818.4, 426.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent5",
    locationMap: "10.7, 818.4, 407.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent5",
    locationMap: "10.7, 802, 417",
  },

  //  5 bottom right parent 5
  {
    nameMap: "subParent4BottomCenterBottomRightContainer",
    locationMap: "33, 752,446",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent4",
    locationMap: "10.7, 752, 426.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent4",
    locationMap: "10.7, 735.5, 436.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent4",
    locationMap: "10.7, 735.5, 455.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent4",
    locationMap: "10.7, 752, 465",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent4",
    locationMap: "10.7, 768.4, 455.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent4",
    locationMap: "10.7, 768.4, 436.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent4",
    locationMap: "10.7, 752, 446",
  },
  //  6 top right parent 6
  {
    nameMap: "subParent3BottomLeftBottomRightContainer",
    locationMap: "33, 702, 417",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent3",
    locationMap: "10.7, 702, 397.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent3",
    locationMap: "10.7, 685.5, 407.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent3",
    locationMap: "10.7, 685.5, 426.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent3",
    locationMap: "10.7, 702, 436",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent3",
    locationMap: "10.7, 718.4, 426.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent3",
    locationMap: "10.7, 718.4, 407.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent3",
    locationMap: "10.7, 702, 417",
  },
  //  7 top right parent 7
  {
    nameMap: "subParent2TopLeftBottomRightContainer",
    locationMap: "33, 702, 359",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_Buttom_Right_SubParent2",
    locationMap: "10.7, 702, 339.8",
  },
  {
    nameMap: "Child2_Buttom_Right_SubParent2",
    locationMap: "10.7, 685.5, 349.2",
  },
  {
    nameMap: "Child3_Buttom_Right_SubParent2",
    locationMap: "10.7, 685.5, 368.3",
  },
  {
    nameMap: "Child4_Buttom_Right_SubParent2",
    locationMap: "10.7, 702, 378",
  },
  {
    nameMap: "Child5_Buttom_Right_SubParent2",
    locationMap: "10.7, 718.4, 368.3",
  },
  {
    nameMap: "Child6_Buttom_Right_SubParent2",
    locationMap: "10.7, 718.4, 349.2",
  },
  {
    nameMap: "Child7_Buttom_Right_SubParent2",
    locationMap: "10.7, 702, 359",
  },
];
