import { ethers } from "ethers";
import abi from "../../../../configs/abis/ballot-contract.json";
import config from "../../../../configs";

const contractAddress = config.get("smartContract.PROPOSAL_ADDRESS");
let provider;
if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
}

// ^ Helper functions

// create a contract instance
const makeContract = async () => {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    return contract;
};

/**
* get proposals list function from contract
* author Mahmoud Skaik
 */
export const getProposals = async () => {
    const contract = await makeContract();
    var proposals = await contract.getProposalsList(0, 100);
    proposals = await Promise.all(
        proposals.proposals_.map(async (item, index) => {
            let myItem = {
                id: parseInt(item.id),
                title: item.title,
                url: item.url,
                numberOfLike: parseInt(item.numberOfLike),
                numberOfDislike: parseInt(item.numberOfDislike),
                softCap: parseInt(item.softCap),
                status: new Date(parseInt(item.closeAt) * 1000).toLocaleString() > new Date().toLocaleString() ? 'Active' : 'Inactive',
                createdAt: new Date(parseInt(item.createdAt) * 1000).toLocaleString('en-GB'),
                closeAt: new Date(parseInt(item.closeAt) * 1000).toLocaleString('en-GB'),
            };
            return myItem;
        })
    );
    return proposals;
}

/**
* add new proposal function from contract
* author Mahmoud Skaik
 */
export const addProposal = async (proposalData) => {
    const contract = await makeContract();
    const { title, url, endDate, rate } = proposalData;
    const formatedEndDate = new Date(endDate).getTime() / 1000;

    return await contract.createNewProposal(title, url, "0x" + Number(rate * 10 ** 16).toString(16), formatedEndDate)

}

/**
* edit proposal url function from contract
* author Mahmoud Skaik
 */
export const editUrl = async (id, url) => {
    const contract = await makeContract();
    return await contract.setUrlByProposal(id, url)
}