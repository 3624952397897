import React from "react";
import "./style.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "./Form";
function GetTouch(props) {
  /** import the Form Component  | khaled Mofeed | 29/10/2022 */
  return (
    <Row className="justify-content-center align-items-center min-height-100per Team GetTouch">
      <Col md={10} xl={9}>
        <div className="title-div">
          <h1 id="id_titleGetTouch">Get in touch</h1>
        </div>
        <Row className="justify-content-center align-items-center bg-border-gradiant contact-form-bg">
          <Col lg={10} xl={9}>
            <Form />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GetTouch;
