/**
 * use yup schema to validator object which contains the error messages
 * author Khaled Mofeed
 */
import * as yup from "yup";
export default yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  description: yup.string().min(20).required(),
  categories: yup.array().required("Categories date is required"),
  start_date: yup.date().required("Start date is required"),
  start_time: yup.string().required("Start time is required"),
  end_date: yup.date().required("End date is required"),
  end_time: yup.string().required("End time is required"),
  location: yup.string().required("Location is required"),
  additional_info: yup.string().required("Additional info is required"),
  cover: yup.string().required("Event image is required"),
});
