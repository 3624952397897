import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Layout/Footer";
import MainHeader from "../../Layout/Header";
import Filter from "./Filter";
import NftMarketPlace from "./NftMarketPlace";
export default function ContainerPage() {
  // this is the Container of MarketPlace Page components have:
  // Filter, component for fillter the data tha viewed in the All NFT Card component
  // NftMarketPlace, Component for view all the NFT in the system by fillterd
  // | khaled Mofeed

  return (
    <>
      <Container fluid>
        <MainHeader />
        <Row className="justify-content-center DistributionVesting">
          <Col md={12} xl={10} className="mb-0">
            <Row>
              <Col md={12} xl={12}>
                <Row>
                  <Filter />
                  <NftMarketPlace />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}
