export const config_D_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa4",
    locationMap: "100, 448, 212",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
    section: "D",
  },
  // 1 top parent 1
  {
    nameMap: "subParentLeft",
    locationMap: "33, 448, 212",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1LeftCenter",
    locationMap: "10.7, 448, 192.8",
  },
  {
    nameMap: "Child2_SubParent1LeftCenter",
    locationMap: "10.7, 431.5, 202.2",
  },
  {
    nameMap: "Child3_SubParent1LeftCenter",
    locationMap: "10.7, 431.5, 221.3",
  },
  {
    nameMap: "Child4_SubParent1LeftCenter",
    locationMap: "10.7, 448, 231",
  },
  {
    nameMap: "Child5_SubParent1LeftCenter",
    locationMap: "10.7, 464.4, 221.3",
  },
  {
    nameMap: "Child6_SubParent1LeftCenter",
    locationMap: "10.7, 464.4, 202.2",
  },
  {
    nameMap: "Child7_SubParent1LeftCenter",
    locationMap: "10.7, 448, 212",
  },
  // 2 top left parent 2
  {
    nameMap: "subParentTop_LeftCenter",
    locationMap: "33, 448, 154",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Top_LeftCenter",
    locationMap: "10.7, 448, 134.8",
  },
  {
    nameMap: "Child2_SubParent1Top_LeftCenter",
    locationMap: "10.7, 431.5, 144.2",
  },
  {
    nameMap: "Child3_SubParent1Top_LeftCenter",
    locationMap: "10.7, 431.5, 163.3",
  },
  {
    nameMap: "Child4_SubParent1Top_LeftCenter",
    locationMap: "10.7, 448, 173",
  },
  {
    nameMap: "Child5_SubParent1Top_LeftCenter",
    locationMap: "10.7, 464.4, 163.3",
  },
  {
    nameMap: "Child6_SubParent1Top_LeftCenter",
    locationMap: "10.7, 464.4, 144.2",
  },
  {
    nameMap: "Child7_SubParent1Top_LeftCenter",
    locationMap: "10.7, 448, 154",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParentLeft_Top_LeftCenter",
    locationMap: "33, 398, 183",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Top_LeftCenter",
    locationMap: " 10.7,398,163.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 381.5, 173.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 381.5, 192.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 398, 202",
  },
  {
    nameMap: "Child5_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 414.4, 192.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 414.4,  173.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Top_LeftCenter",
    locationMap: "10.7, 398, 183",
  },

  // 4 bottom parent 4
  {
    nameMap: "subParentLeft_Bottom_LeftCenter",
    locationMap: "33, 398, 241",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 398, 221.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 381.5, 231.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 381.5, 250.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 398, 260",
  },
  {
    nameMap: "Child5_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 414.4, 250.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 414.4, 231.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Bottom_LeftCenter",
    locationMap: "10.7, 398, 241",
  },

  //  5 bottom right parent 5
  {
    nameMap: "subParentBottom_LeftCenter",
    locationMap: "33, 448, 270",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 448, 250.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 431.5, 260.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 431.5, 279.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 448, 289",
  },
  {
    nameMap: "Child5_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 464.4,279.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 464.4,260.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_LeftCenter",
    locationMap: "10.7, 448, 270",
  },
  //  6 top right parent 6
  {
    nameMap: "subParentBottom_Right_LeftCenter",
    locationMap: "33, 498, 241",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 498, 221.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 481.5,231.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 481.5, 250.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 498, 260",
  },
  {
    nameMap: "Child5_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 514.4, 250.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 514.4, 231.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_Right_LeftCenter",
    locationMap: "10.7, 498, 241",
  },
  //  7 top right parent 7
  {
    nameMap: "subParentTop_Right_LeftCenter",
    locationMap: "33, 498, 183",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7,498, 163.8",
  },
  {
    nameMap: "Child2_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 481.5, 173.2",
  },
  {
    nameMap: "Child3_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 481.5, 192.3",
  },
  {
    nameMap: "Child4_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 498, 202",
  },
  {
    nameMap: "Child5_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 514.4, 192.3",
  },
  {
    nameMap: "Child6_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 514.4, 173.2",
  },
  {
    nameMap: "Child7_SubParent1Top_Right_LeftCenter",
    locationMap: "10.7, 498, 183",
  },
];
