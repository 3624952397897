import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./style.css";
import Metamask from "../../../assets/metamask.png";
/*  This Component Update Network view when there is wrong in network | khaled Mofeed */
function Index({ openChainModal }) {
  return (
    <Row>
      <Col md={{ offset: 1, span: 10 }} className="text-center">
        <Row className="disc-wallet-row align-items-center">
          <Col
            xs={{ span: 12, order: "last" }}
            lg={{ span: 6, order: "first" }}
            md={{ span: 5, order: "first" }}
            className="wallet-connect-text"
          >
            <h1 className="NetworkUpdate">Wrong Network</h1>
            <p className="mb-1 pt-2 text-left">
              You need to be connected to <b>Smart Chain - Testnet </b> to use
              this app, please switch your network to continue.
            </p>
            <span onClick={openChainModal} className="text-center w-75">
              Switch to <b>Smart Chain - Testnet</b>
            </span>
          </Col>
          <Col
            xs={12}
            lg={{ offset: 1, span: 5 }}
            md={5}
            className="text-center"
          >
            <Image
              fluid
              className="metamask-img"
              src={Metamask}
              alt="Metamask"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Index;
