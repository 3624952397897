import React, { useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Price from './Price'

import {
  StyledDataCell,
  StyledHeadCell,
  StyledTablePaper,
  StyledTableRow,
} from "./styled";
import { useLatestOffers } from "../../../../../hooks/useMarketplace";

const LandLatestOffers = ({tokenId, withEdit = false, needToRefetch }) => {
  const { data, isLoading, error, refetch } = useLatestOffers(tokenId, 1);
  const latestOffers = data?.data?.data?.offers;

  useEffect(() => {
    if (needToRefetch) {
        refetch(); 
    }
}, [needToRefetch])

  return (
    <TableContainer component={StyledTablePaper} className='w-100'>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeadCell>From</StyledHeadCell>
            <StyledHeadCell>Price</StyledHeadCell>
            <StyledHeadCell>Time Left</StyledHeadCell>
            {withEdit && <StyledHeadCell>Actions</StyledHeadCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {latestOffers?.length !== 0 ? latestOffers?.map(item => (
            <StyledTableRow
              key={item.from}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledDataCell>{item.from}</StyledDataCell>
              <StyledDataCell>
                <Price icon={`${process.env.REACT_APP_ASSETS}/img/telv_filled.svg`} price={200} symbol={""} />
              </StyledDataCell>
              <StyledDataCell>{item.timeLeft}</StyledDataCell>
              {withEdit && (
                <StyledDataCell>
                  <Button variant="outlined" color="success">
                    Accept
                  </Button>
                  <Button variant="outlined" color="error">
                    Reject
                  </Button>
                </StyledDataCell>
              )}
            </StyledTableRow>
          ))
         : <StyledTableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0, padding: "40px" } }}
        >
          <StyledDataCell className="mx-auto text-center " colSpan={withEdit ? 4 : 3}>No Offers</StyledDataCell>
        </StyledTableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LandLatestOffers;
