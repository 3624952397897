import styled from "styled-components";

export const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0 0 0 / 30%)",
    zIndex: 2,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: "322px",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: "20px 20px 30px",
    border: 0,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "18px",
  },
};

export const ModalContentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 20px;
    height: 20px;
  }
`;
