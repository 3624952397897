import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../../Layout/Footer";
import MainHeader from "../../../Layout/Header";
import AvatarNameBio from "./components/AvatarNameBio";
import HeadEdit from "./components/HeadEdit";
import InfoForm from "./components/InfoForm";
function index(props) {
  // this is the edit profile page have:
  // HeadEdit , header of page component
  // AvatarNameBio , left side of page component have avatar , name and bio
  // InfoForm, right side of page component that user needs to modify his data
  // | khaled Mofeed
  return (
    <>
      <Container fluid>
        <MainHeader />
        <Row className="justify-content-center DistributionVesting min-height-100per">
          <Col md={10} xl={10} className="mb-5">
            <Row>
              <HeadEdit />
            </Row>
            <Row>
              <Col md={4} xl={4} className="avatarImage">
                <AvatarNameBio />
              </Col>
              <Col></Col>
              <Col md={7} xl={7}>
                <InfoForm />
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default index;
