import React from "react";
import { Col, Row } from "react-bootstrap";
import { useUploadImage } from "../../../Hooks/useUploadImage";
import EditAvatarProfile from "../../EditAvatarProfile";

function AvatarNameBio(props) {
  // AvatarNameBio component , this s the left side of page component have avatar , name and bio
  const { userData } = useUploadImage("avatar");
  return (
    <Row>
      <Col md={5}>
        <EditAvatarProfile />
      </Col>

      <Col md={7} className="pt-3">
        <h6 className="nickName mt-2">{userData?.name}</h6>
        <p className="descriptionLable">{userData?.bio} 🙌</p>
      </Col>
    </Row>
  );
}

export default AvatarNameBio;
