import {
  HTTP_DELETE_METHOD,
  HTTP_GET_METHOD,
  HTTP_POST_METHOD,
} from "../constants";

export const endpoints = {
  auth: {
    Login: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/auth/login",
    },
    Logout: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/auth/logout",
    },
    ForgetPassword: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/auth/forget",
    },
    ResetPassword: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/auth/reset",
    },
  },
  AdminUsers: {
    list: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/admin/list",
    },
    CreateNewAdmin: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/admin/store",
    },
    UpdateAdmin: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/admin/update",
    },
    DeleteAdmin: {
      method: HTTP_DELETE_METHOD,
      url: "/api/v1/back-office/admin/delete",
    },
    ChangeStatus: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/admin/change-status",
    },
  },
  Roles: {
    GetRoleList: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/role/list",
    },
    CreateNewRole: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/role/store",
    },
    UpdateRole: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/role/update",
    },
    DeleteRole: {
      method: HTTP_DELETE_METHOD,
      url: "/api/v1/back-office/role/delete",
    },
    GetAllPermissions: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/role/getPermissions",
    },
    ChangeStatus: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/role/change-status",
    },
  },
  Events: {
    list: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/event/list",
    },
    DeleteRole: {
      method: HTTP_DELETE_METHOD,
      url: "/api/v1/back-office/event/delete",
    },
    ChangeStatus: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/event/change-status",
    },
  },
  Profile: {
    GetUserData: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/profile/data",
    },
    Update: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/profile/update",
    },
    changePassword: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/profile/change-password",
    },
    changeAvatar: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/profile/change-avatar",
    },
  },
  _2FA: {
    GetData: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/back-office/2fa/data",
    },
    Enable: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/2fa/enable",
    },
    Disable: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/2fa/disable",
    },
    Login: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/back-office/2fa/login",
    },
  },
  TeleversUserApi: {
    GetSignMessage: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/get-sign-message",
    },
    RegisterCheckUserAddress: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/register-check",
    },
    Logout: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/logout",
    },
  },
  ProfileData: {
    GetProfileData: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/profile/data",
    },
    UpdateProfile: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/profile/update",
    },
    changeAvatarCover: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/profile/change-image",
    },
  },
  Following: {
    GetUsers: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/follow/get-users",
    },
    GetFollowings: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/follow/get-followings",
    },
    GetFollowers: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/follow/get-followers",
    },
    Follow_UnFollow: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/follow/follow-unfollow",
    },
  },
  Event: {
    GetCategories: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/event/get-categories",
    },
    CreateEvent: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/event/create",
    },
    Attend: {
      method: HTTP_POST_METHOD,
      url: "/api/v1/event/attend",
    },
    GetAllEvents: {
      method: HTTP_GET_METHOD,
      url: "/api/v1/event/get",
    },
  },
  Marketplace: {
    AllInNftWithOutAuth: {
      method: HTTP_GET_METHOD,
      url: "/client/api/v1/marketplace",
    },
    OwnerOf: {
      method: HTTP_GET_METHOD,
      url: "/client/api/v1/marketplace/owner-of",
    },
    NftData : {
      method: HTTP_GET_METHOD,
      url: (id) => `/client/api/v1/nft-marketplace/${id}`,
    },
    NftPurchaseHistory : {
      method: HTTP_GET_METHOD,
      url: (id) => `/client/api/v1/marketplace/purchase-history/${id}`,
  },
  NftOffers : {
      method: HTTP_GET_METHOD,
      url: (id) => `/client/api/v1/marketplace/offers-list/${id}`,
  },
  },
};
