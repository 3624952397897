import React, { useContext } from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./style.css";
import Metamask from "../../../assets/metamask.png";
import { AppContext } from "../../../scripts/context";
import { useEffect } from "react";
/*  This Component view when disconnect to wallet | khaled Mofeed */
function Index({ openConnectModal, account, connected }) {
  const { state: { connectWallet } = {}, disconnect } =
    useContext(AppContext) || {};

  useEffect(() => {
    if (!account) {
      disconnect();
    }
  }, [account, connected]);
  return (
    <Row>
      <Col md={{ offset: 1, span: 10 }} className="text-center">
        <Row className="disc-wallet-row align-items-center">
          <Col
            xs={{ span: 12, order: "last" }}
            lg={{ span: 6, order: "first" }}
            md={{ span: 5, order: "first" }}
            className="wallet-connect-text"
          >
            <h1>Connect To Your Wallet</h1>
            <span onClick={openConnectModal} className="text-center">
              Connect to a wallet
            </span>
          </Col>
          <Col
            xs={12}
            lg={{ offset: 1, span: 5 }}
            md={5}
            className="text-center"
          >
            <Image
              fluid
              className="metamask-img"
              src={Metamask}
              alt="Metamask"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Index;
