export const config_E_Section = [
  //******************** A Section  ***************
  {
    nameMap: "pointDataHexa7",
    locationMap: "100, 448, 388",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
    section: "E",
  },
  // 1 top parent 1
  {
    nameMap: "subParentLeftBottom",
    locationMap: "33, 448, 388",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1LeftBottomCenter",
    locationMap: "10.7, 448, 368.8",
  },
  {
    nameMap: "Child2_SubParent1LeftBottomCenter",
    locationMap: "10.7, 431.5, 378.2",
  },
  {
    nameMap: "Child3_SubParent1LeftBottomCenter",
    locationMap: "10.7, 431.5, 397.3",
  },
  {
    nameMap: "Child4_SubParent1LeftBottomCenter",
    locationMap: "10.7, 448, 407",
  },
  {
    nameMap: "Child5_SubParent1LeftBottomCenter",
    locationMap: "10.7, 464.4, 397.3",
  },
  {
    nameMap: "Child6_SubParent1LeftBottomCenter",
    locationMap: "10.7, 464.4, 378.2",
  },
  {
    nameMap: "Child7_SubParent1LeftBottomCenter",
    locationMap: "10.7, 448, 388",
  },
  // 2 top left parent 2
  {
    nameMap: "subParentTop_LeftBottomCenter",
    locationMap: "33, 448, 330",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 448, 310.8",
  },
  {
    nameMap: "Child2_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 431.5, 320.2",
  },
  {
    nameMap: "Child3_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 431.5, 339.3",
  },
  {
    nameMap: "Child4_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 448, 349",
  },
  {
    nameMap: "Child5_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 464.4, 339.3",
  },
  {
    nameMap: "Child6_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 464.4, 320.2",
  },
  {
    nameMap: "Child7_SubParent1Top_LeftBottomCenter",
    locationMap: "10.7, 448, 330",
  },
  // 3 bottom left parent 3
  {
    nameMap: "subParentLeft_Top_LeftBottomCenter",
    locationMap: "33, 398, 359",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Top_LeftBottomCenter",
    locationMap: " 10.7,398, 339.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 381.5, 349.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 381.5, 368.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 398, 378",
  },
  {
    nameMap: "Child5_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 414.4, 368.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 414.4, 349.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Top_LeftBottomCenter",
    locationMap: "10.7, 398, 359",
  },

  // 4 bottom parent 4
  {
    nameMap: "subParentLeft_Bottom_LeftBottomCenter",
    locationMap: "33, 398, 417",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 398, 397.8",
  },
  {
    nameMap: "Child2_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 381.5, 407.2",
  },
  {
    nameMap: "Child3_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 381.5, 426.3",
  },
  {
    nameMap: "Child4_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 398, 436",
  },
  {
    nameMap: "Child5_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 414.4, 426.3",
  },
  {
    nameMap: "Child6_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 414.4, 407.2",
  },
  {
    nameMap: "Child7_SubParent1Left_Bottom_LeftBottomCenter",
    locationMap: "10.7, 398, 417",
  },

  //  5 bottom right parent 5
  {
    nameMap: "subParentBottom_LeftBottomCenter",
    locationMap: "33, 448, 446",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 448, 426.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 431.5, 436.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7,431.5, 455.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 448, 465",
  },
  {
    nameMap: "Child5_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 464.4, 455.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 464.4, 436.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_LeftBottomCenter",
    locationMap: "10.7, 448, 446",
  },
  //  6 top right parent 6
  {
    nameMap: "subParentBottom_Right_LeftBottomCenter",
    locationMap: "33, 498, 417",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 498, 397.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 481.5,407.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 481.5, 426.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 498, 436",
  },
  {
    nameMap: "Child5_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 514.4, 426.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 514.4, 407.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_Right_LeftBottomCenter",
    locationMap: "10.7, 498, 417",
  },
  //  7 top right parent 7
  {
    nameMap: "subParentBottom_Left_CenterCenter",
    locationMap: "33, 498, 359",
    fill: "#482C68",
    unClick: true,
  },
  {
    nameMap: "Child1_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7,498, 339.8",
  },
  {
    nameMap: "Child2_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 481.5, 349.2",
  },
  {
    nameMap: "Child3_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 481.5, 368.3",
  },
  {
    nameMap: "Child4_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 498, 378",
  },
  {
    nameMap: "Child5_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 514.4, 368.3",
  },
  {
    nameMap: "Child6_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 514.4, 349.2",
  },
  {
    nameMap: "Child7_SubParent1Bottom_Left_CenterCenter",
    locationMap: "10.7, 498, 359",
  },
];
