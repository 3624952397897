import { ethers } from "ethers";
import abi from "../../../configs/abis/vesting-contract.json";
import config from "../../../configs";

/*
 * 1. Make contract
 * 2. Helper functions
 * 3. Export functions
 * 4. Export tableData
 the functions here is mostly similar to the ones in presale
 */

const vestingAddress = config.get("smartContract.VESTING_ADDRESS");
// const proposalAddress = config.get('smartContract.PROPOSAL_ADDRESS');

const makeContract = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  await window.ethereum.request({ method: "eth_requestAccounts" });
  const signer = provider.getSigner();
  const contract = new ethers.Contract(vestingAddress, abi, signer);
  return contract;
};

// Helper functions

const singleValueFunc = async (func, parseValue = true) => {
  const contract = await makeContract();
  const value = await contract[func]();
  return parseValue ? valueParser(value) : value;
};

const valueParser = (value) => {
  return parseInt(value) / 10 ** 16;
};

const tableData = async (func) => {
  const deployedPresaleContract = await makeContract();
  const rawData = await deployedPresaleContract[func]();
  const tableData = await Promise.all(
    rawData.map(async (item, index) => ({
      id: `${index}-${item.releaseDate}`,
      amount: valueParser(item.telvAmount),
      date: new Date(parseInt(item.releaseDate) * 1000).toLocaleString([], {
        hour12: false,
      }),
      status:
        item.status === 0 ? "Claimed" : item.status === 1 ? "Ready" : "Locked",
    }))
  );
  return tableData;
};

export const getAddress = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
};

export const schedule = async () => {
  const data = await tableData("schedual");
  return data;
};

export const getTotalNumberVestingPeriods = singleValueFunc.bind(
  null,
  "getTotalNumberVestingPeriods",
  false
);

export const getUserTotalTELVOwned = singleValueFunc.bind(
  null,
  "getUserTotalTELVOwned"
);

export const getUserTotalTELVClaimed = singleValueFunc.bind(
  null,
  "getUserTotalTELVClaimed"
);

export const getUserTotalTELVAvailable = singleValueFunc.bind(
  null,
  "getUserTotalTELVAvailable"
);

export const claimAvailableTELV = async () => {
  const contract = await makeContract();
  const tx = await contract.claimAvailableTELV();
  await tx.wait();
  return tx;
};
