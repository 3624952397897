import React from "react";
import { Typography } from "@mui/material";
import { StyledPrice } from "./styled";
import { Image } from "react-bootstrap";

const Price = ({ price, symbol, icon }) => {
  return (
    <StyledPrice>
      <Image
        width={18.04}
        height={18.4}
        src={icon ?? "/img/telv.svg"}
        alt={symbol}
      />
      <Typography component="p" variant="body4">
        {price + " "}
        {symbol}
      </Typography>
    </StyledPrice>
  );
};

export default Price;
