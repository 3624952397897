import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { AppContext } from "../../../../../scripts/context";

function HeadEdit(props) {
  // HeadEdit component, this is header of edit profile page
  const { userData } = useContext(AppContext);
  return (
    <Col md={12} xl={12} className="mb-5 pb-3 border-bottom">
      <h5 className="nickName">{userData && userData?.name}</h5>
      <p className="descriptionEditLable">
        You can set preferred display name, create
        <span> Your Profile URL </span> and manage other personal settings.
      </p>
    </Col>
  );
}

export default HeadEdit;
