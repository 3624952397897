import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../../../Layout/Footer";
import MainHeader from "../../../../Layout/Header";
import SortMapLis from "../../SortMapLis";
import ContainerMap from "./ContainerMap";
function ContainerPage(props) {
  // this is the Container of Map Component || khaled Mofeed
  return (
    <>
      <Container fluid>
        <MainHeader />
        <Row className="justify-content-center DistributionVesting">
          <Col md={12} xl={10} className="mb-0">
            <Row>
              <Col md={12} xl={12}>
                <Row>
                  <Col md={12} xl={12} className="TrendingSection">
                    <Row className="marketplace-list ProfileCard mapLinks">
                      <SortMapLis />
                      <ContainerMap />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default ContainerPage;
