export const socialMediaConfig = [
  {
    id: "facebook",
    link: "#",
    iconClass: "fab fa-facebook-f",
  },
  {
    id: "twitter",
    link: "#",
    iconClass: "fab fa-twitter",
  },
  {
    id: "instagram",
    link: "#",
    iconClass: "fab fa-instagram",
  },
  {
    id: "youtube",
    link: "#",
    iconClass: "fab fa-youtube",
  },
  {
    id: "telegram",
    link: "#",
    iconClass: "fab fa-telegram-plane",
  },
  {
    id: "medium",
    link: "#",
    iconClass: "fab fa-medium-m",
  },
  {
    id: "discord",
    link: "#",
    iconClass: "fab fa-discord",
  },
];
