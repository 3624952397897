import React from "react";
import ContainerPageSubmitEvent from "./components/ContainerPageSubmitEvent";
function index(props) {
  // this is the Submit Event Page
  // | khaled Mofeed
  return (
    <>
      <ContainerPageSubmitEvent />
    </>
  );
}

export default index;
