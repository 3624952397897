import { styled, SxProps, Theme } from "@mui/material/styles";
import React from "react";
import {
  Avatar,
  Button,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { pxToRem } from "../theme";
import EditIcon from "./EditSvg";
import UserImg from "../../../../assets/home/marketplace/user.png";

const StyledOwnerCard = styled(Card)(({ theme }) => ({
  background: "#2d145f",
  border: "1px solid #542ea1",
  padding: `${pxToRem(24)} ${pxToRem(28)}`,
  borderRadius: "1rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem",
  },
}));

const ControlsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
}));

const StyledCardHeader = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row-reverse",
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  marginRight: "31px",
  [theme.breakpoints.down("sm")]: {
    width: "50px",
    height: "50px",
    marginRight: "12px",
  },
}));

const UserAvatarWithInfo = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "-1rem",
}));

const OwnerLabel = styled(Typography)(({ theme }) => ({
  color: "#777E90",
  fontSize: "1rem",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: ".75rem",
    lineHeight: "15px",
  },
  fontWeight: 400,
}));

const OwnerInfo = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  lineHeight: "2rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const JumpInBtn = styled(Button)(() => ({
  // marginTop: "28px",
  height: "fit-content",
}));

const OwnerCard = ({ owner, isOwner, onClick, onEdit, onJump, sx }) => {
  const isDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <StyledOwnerCard sx={sx} onClick={onClick}>
      <UserAvatarWithInfo>
        {owner?.avatar && (
          <UserAvatar src={owner.avatar} alt="owner">
            {owner?.name}
          </UserAvatar>
        )}
        <div>
          <OwnerLabel>The Owner</OwnerLabel>
          <OwnerInfo>{owner?.name || "------"}</OwnerInfo>
        </div>
      </UserAvatarWithInfo>
      <ControlsContainer>
        {isOwner && (
          <IconButton
            size="small"
            sx={{
              marginBottom: pxToRem(12),
            }}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <EditIcon width={isDownSM ? 20 : 30} height={isDownSM ? 20 : 30} />
          </IconButton>
        )}
        <JumpInBtn
          variant="contained"
          color="error"
          endIcon={<ArrowCircleRightOutlinedIcon />}
          onClick={(e) => {
            e.stopPropagation();
            onJump();
          }}
        >
          JUMP IN
        </JumpInBtn>
      </ControlsContainer>
    </StyledOwnerCard>
  );
};

export default OwnerCard;
