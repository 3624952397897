export const DARK_THEME = {
  backgroundImagePath: "/img/Background.png",
  brandColor: "#FFFFFF",

  colors: {
    bodyBg:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(145.99% 70.81% at 50% 37.06%, #103062 30.05%, #032658 83.66%, #011025 100%)",
    nav_bg: "#11346e",
    nav_bg_2:
      "linear-gradient(180deg, #102039 80%, rgba(16, 32, 57, 0) 100%)",

    ellipse_0: "#FFFFFF",
    ellipse_1: "#FFFFFF",
    ellipse_2: "#5FF7FF",
    ellipse_3: "linear-gradient(102.19deg, #031f45 1.65%, #052556 69.89%)",
    ellipse_4: "#062657",
    ellipse_5:
      "linear-gradient(180deg, rgba(22, 59, 150, 0.35) 18.7%, rgba(22, 59, 150, 0) 100%)",
    ellipse_6: "#163B96",
    ellipse_7: "rgba(0, 0, 0, 0.16);",

    ellipse_8: "#163B96",
    ellipse_9: "#0D6BBD",
    ellipse_10: "#178AAE",
    ellipse_11: "#17AE7F",
    ellipse_12: "#051E40",

    ellipse_13: "#a98eee",
    ellipse_14: "#031b40",
    ellipse_15: "#001738ad",
    ellipse_16: "#ffffffff",

    ellipse_17: "#163193",
    ellipse_18: "#723CC2",
    ellipse_19: "#3C82C2",
    ellipse_20: "#ffffff",

    ellipse_21: "#031836",
    ellipse_22: "#173193",

    ellipse_23: "#032046",
    ellipse_24: "#ffffff",

    ellipse_25: "#144a9980",

    ellipse_26:
      "linear-gradient(0deg, rgba(0, 12, 33, 0.18) 17.82%, rgba(43, 121, 255, 0.18) 81.59%)",
    ellipse_27: "#1F4E82",

    ellipse_28: "#ffffff",
    ellipse_29: "#38A0FF",

    ellipse_30:
      "linear-gradient(180deg, rgba(6, 38, 87, 0) 26.62%, rgba(2, 46, 113, 0.81) 88.85%)",

    ellipse_31:
      "linear-gradient(180deg, rgba(22, 59, 150, 0.35) 18.7%, rgba(22, 59, 150, 0.35) 100%)",

    ellipse_32: " 20.6564px 10.3282px 33.7026px rgba(0, 7, 45, 0.75)",
    ellipse_33: "#FFFFFF",

    ellipse_34: "#ffffff",
    ellipse_35: "#481940",

    ellipse_36: "rgba(6, 38, 87, 0.42)",
    ellipse_37: "rgba(30, 88, 200, 0.42)",
    ellipse_38: "#5434A4",
    ellipse_39: "rgba(149, 110, 249, 0.5)",
  },
};

export const LIGHT_THEME = {
  backgroundImagePath: "/img/Background-light.png",
  brandColor: "#011D61",

  colors: {
    bodyBg: "linear-gradient(180deg, #D1DFF8 0%, #FFFFFF 100%)",
    nav_bg: "#d9e5f9",
    nav_bg_2:
      "linear-gradient(180deg, #B5CFFF 80%, rgba(184, 206, 246, 0) 100%)",

    ellipse_0: "#011D61",
    ellipse_1: "#6D7D97",
    ellipse_2: "#011D61",
    ellipse_3: "#F9FBFF",
    ellipse_4: "linear-gradient(180deg, #E8EFFD 0%, #FFFFFF 100%);",
    ellipse_5:
      "linear-gradient(180deg, #E6EFFF 18.7%, rgba(230, 239, 255, 0.15) 100%);",
    ellipse_6: "#E6EFFF",
    ellipse_7: "#ffffff",

    ellipse_8: "#305CC6",
    ellipse_9: "#2B7ACD",
    ellipse_10: "#2597D4",
    ellipse_11: "#20B5DB",
    ellipse_12: "#E6EFFF",

    ellipse_13: "rgba(255, 255, 255, 0.7)",
    ellipse_14: "#f5f7ff",
    ellipse_15: "#FEFEFFad",
    ellipse_16: "#4F5364",

    ellipse_17: "#123384",
    ellipse_18: "#0F48C3",
    ellipse_19: "#58709E",
    ellipse_20: "#123384",

    ellipse_21: "#CCD2DF",
    ellipse_22: "#58709E",

    ellipse_23: "#ffffff",
    ellipse_24: "#6d7d97",

    ellipse_25: "#CCD2DF",

    ellipse_26:
      "linear-gradient(180deg, rgba(172, 201, 255, 0.18) 17.82%, rgba(123, 172, 255, 0.18) 81.59%)",
    ellipse_27: "#163192",

    ellipse_28: "#163192",
    ellipse_29: "#6D7D97",

    ellipse_30:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",

    ellipse_31: "#FFFFFF",

    ellipse_32: "20.6564px 10.3282px 33.7026px rgba(184, 184, 184, 0.75)",
    ellipse_33: "#58709E",

    ellipse_34: "#3962F5",

    ellipse_35: "#f8b5c1",
  
    ellipse_36: "#E9F1FF",
    ellipse_37: "rgba(176, 187, 208, 0.42)",
    ellipse_38: "rgba(218, 192, 255, 0.3);",
    ellipse_39: "rgba(218, 192, 255, 0.5);",
  },
};
