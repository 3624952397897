import React from "react";
import { Col } from "react-bootstrap";

function HeadSubmit(props) {
  // HeadEdit component, this is header of edit profile page
  return (
    <Col md={12} xl={12} className="mb-5 pb-3 border-bottom">
      <h5 className="nickName">Submit event</h5>
    </Col>
  );
}

export default HeadSubmit;
