import React from "react";
import ReactDOM from "react-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { InputBaseProps } from "@mui/material";
import { pxToRem } from "../../theme";
// import Input from "./Input";



const TextInput = ({ label, Input, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: props.fullWidth ? "100%" : "inherit",
      }}
    >
      <label
        style={{
          fontSize: "1rem",
          lineHeight: pxToRem(12),
          marginBottom: pxToRem(12),
          marginLeft: pxToRem(2),
        }}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <Input {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextInput;
