/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import { ethers } from "ethers";
import abi from "../configs/abis/ballot-contract.json";
import config from "../configs";
import toast from "react-hot-toast";
const { ethereum } = window;
const ballotContext = createContext(null);
const proposalAddress = config.get("smartContract.PROPOSAL_ADDRESS");
export const BallotProvider = ({ children }) => {
  const [contract, setContract] = useState(null);
  const [supply, setSupply] = useState(0);
  /* This functions for handle single value from smart contract | khaled Mofeed  */
  const singleValueFunc = async (func) => {
    const value = await contract[func]();
    return parseInt(value);
  };
  /* This functions for connect To smart contract | khaled Mofeed  */
  const connectToContract = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(proposalAddress, abi, signer);
      setContract(contract);
    } catch (error) {
      console.log(error);
    }
  };
  // get address
  /* This functions for return totalSupply from smart contract | khaled Mofeed  */
  const init = async () => {
    try {
      if (!contract) return;
      const [totalSupply] = await Promise.allSettled([
        singleValueFunc("totalSupply"),
      ]);
      setSupply(totalSupply);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // if (!window.ethereum) {
    //   toast.error("MetaMask is not installed");
    //   return;
    // }
    connectToContract();
  }, [window.ethereum]);

  useEffect(() => {
    init();
  }, [contract]);
  /* This functions for handle the data array from smart contract | khaled Mofeed  */
  const tableData = async (func, args, parameter) => {
    if (!contract) return;
    const { totalList_, ...rowData } = await contract[func](...args);
    const dataArr = rowData[parameter];

    const transactions = await Promise.all(
      dataArr.map(async (item, index) => {
        const myItem = {
          id: item.id,
          key: item.id + index,
          title: item.title,
          closeAt: new Date(item.closeAt * 1000),
          createdAt: new Date(item.createdAt * 1000).toLocaleString([], {
            hour12: false,
          }),
          numberOfLike: parseInt(item.numberOfLike),
          numberOfDislike: parseInt(item.numberOfDislike),
          softCap: parseInt(item.softCap),
        };
        return myItem;
      })
    );
    const totalList = parseInt(totalList_);
    return { transactions, totalList };
  };

  /* this function is used to get the user's total transaction list | khaled Mofeed */
  const getProposalsList = async (page, limit) => {
    const args = [page, limit];
    const data = await tableData("getProposalsList", args, "proposals_");
    return data;
  };
  /* This functions for handle the like and dislike | khaled Mofeed  */
  const voteHandler = async (id, impression) => {
    try {
      if (!contract) return;
      await contract["vote"](id, impression);
    } catch (error) {
      console.error(error);
    }
  };
  const like = (id) => {
    voteHandler(id, true);
  };
  const dislike = (id) => {
    voteHandler(id, false);
  };

  const value = useMemo(
    () => ({
      getProposalsList,
      supply,
      like,
      dislike,
    }),
    [getProposalsList, supply, like, dislike]
  );

  return (
    <ballotContext.Provider value={value}>{children}</ballotContext.Provider>
  );
};

export const useBallot = () => {
  return useContext(ballotContext);
};
