import React from "react";
import { Toaster } from "react-hot-toast";

function ToasterCard(props) {
  /* Helper component toast message view in the top left of screen, Used to show notifications and messages to the user
  * ref : https://react-hot-toast.com/docs 
  | khaled Mofeed | 29/10/2022  */
  return (
    <>
      <Toaster
        position="top-left"
        reverseOrder={false}
        containerStyle={{
          top: 80,
        }}
        toastOptions={{
          className: "styleToast",
          success: {
            iconTheme: {
              primary: "#fff",
              secondary: "#1bc5bd",
            },
            style: {
              background: "#1bc5bd",
              color: "#fff",
              padding: "15px",
              opacity: "0.8",
              borderRadius: "4px",
            },
          },
          error: {
            iconTheme: {
              primary: "#fff",
              secondary: "#f64e60",
            },
            style: {
              background: "#f64e60",
              color: "#fff",
              padding: "15px",
              opacity: "0.8",
              borderRadius: "4px",
            },
          },
        }}
      />
    </>
  );
}

export default ToasterCard;
