/**
 * use yup schema to validator object which contains the error messages
 * author Khaled Mofeed
 */
import * as yup from "yup";
export default yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  mobile: yup
    .string()
    .matches(
      /^[0-9]{8,13}$/,
      "Mobile number must be just numbers and minimum 8 digits and maximum 13 digits long"
    ),
});
