import configFile from './config.development';

export default class Config {
  static get(key) {
    // if key wes object property or nested properties and handle error if key not found
    return key.split('.').reduce((o, x) => {
      if (typeof o === 'undefined' || o === null) {
        return o;
      }
      return o[x];
    }, configFile);
  }
}
