import React from "react";
import SignPage from "../../components/SignPage";
const index = () => {
  return (
    <>
      <SignPage />
    </>
  );
};

export default index;
