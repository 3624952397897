import React, { useContext, useEffect } from "react";
import { useConnectMetaMask } from "../../hooks/useConnectMetaMask";
import { AppContext } from "../../scripts/context";
/*  This Component used if connect wallet call the submitConnectMetaMask function | khaled Mofeed */
function Index({ connected, address, account }) {
  const submitConnectMetaMask = useConnectMetaMask({});
  const { isToken } = useContext(AppContext) || {};

  useEffect(() => {
    // This effect will run whenever the "connected" state changes
    // and only if the condition is met.
    if (connected && !isToken) {
      submitConnectMetaMask();
    }
  }, [connected, address, account]);

  return;
}

export default Index;
