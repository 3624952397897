import { config_A_Section } from "./config_A_Section";
import { config_B_Section } from "./config_B_Section";
import { config_C_Section } from "./config_C_Section";
import { config_D_Section } from "./config_D_Section";
import { config_E_Section } from "./config_E_Section";
import { config_F_Section } from "./config_F_Section";
import { config_G_Section } from "./config_G_Section";
export const config_Parents_Sections = [
  //**** TOP_RIGHT OF RIGHT CENTER PARENT 1 *******
  {
    name: "pointData",
    location: "500, 600, 300",
    fill: "rgb(255 123 176)",
    unClick: true,
  },
  //**** TOP_RIGHT OF RIGHT CENTER PARENT 1 *******
  {
    name: "pointDataHexa8",
    location: "100, 904, 124",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //******* TOP OF RIGHT CENTER PARENT 1 **********
  {
    name: "pointDataHexa9",
    location: "100, 752, 37",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //**** BOTTOM_RIGHT OF RIGHT CENTER PARENT 1 ****
  {
    name: "pointDataHexa10",
    location: "100, 904, 300",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //********* TOP OF TOP CENTER PARENT 1 **********
  {
    name: "pointDataHexa11",
    location: "100, 600, -50",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //*******TOP_LEFT OF TOP CENTER PARENT 1 *******
  {
    name: "pointDataHexa12",
    location: "100, 448, 37",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //****** TOP_LEFT OF LEFT CENTER PARENT 1 *******
  {
    name: "pointDataHexa13",
    location: "100, 296, 124",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //**** BOTTOM_LEFT OF LEFT CENTER PARENT 1 ******
  {
    name: "pointDataHexa14",
    location: "100, 296, 300",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //**** BITTOM_RIGHT OF BITTOM RIGHT CENTER PARENT 1 ****

  {
    name: "pointDataHexa15",
    location: "100, 904, 476",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //******** BITTOM OF BITTOM RIGHT CENTER PARENT 1 *********
  {
    name: "pointDataHexa16",
    location: "100, 752, 563",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //******** BITTOM OF BITTOM CENTER PARENT 1 *********
  {
    name: "pointDataHexa17",
    location: "100, 600, 650",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //******** LEFT_BITTOM OF BITTOM CENTER PARENT 1 *********
  {
    name: "pointDataHexa18",
    location: "100, 448, 563",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
  //***LEFT_BITTOM OF LEFT_BITTOM CENTER PARENT 1 **
  {
    name: "pointDataHexa19",
    location: "100, 296, 476",
    stroke: "rgb(255 123 176)",
    fill: "rgb(25 9 56)",
    unClick: true,
  },
];
export const config_Title_Sections = [
  config_A_Section,
  config_B_Section,
  config_C_Section,
  config_D_Section,
  config_E_Section,
  config_F_Section,
  config_G_Section,
];
