import React from "react";
import Chart from "react-apexcharts";
import { OptionsData, SeriesData } from "../../../../configs/configPolarChart";
/*  Render Line Chart Component for displaying analytical data through infographics and charts
*  Defining the data through the config Polar Chart file
 | khaled Mofeed | 29/10/2022 */

const series = SeriesData;
const options = OptionsData;

function LineChart(props) {
  return (
    <div id="chart">
      <Chart options={options} series={series} type="line" height={400}></Chart>
    </div>
  );
}

export default LineChart;
