import React, { useContext } from "react";
import Pagination from "react-bootstrap/Pagination";
import { AppContext } from "../../../scripts/context";

function Pagenation(props) {
  /**
   * Pagenation Component used for render the data from Api by pagination
   * author Khaled Mofeed
   */
  const { setInPage, inPage, pagination_Options } = useContext(AppContext);
  const pageNumber = Array.from(
    { length: pagination_Options.Pagination_options },
    () => 0
  );
  /**
   * handlePage Function used for set the page in pagination
   * author Khaled Mofeed
   */
  function handlePage(indexpage) {
    setInPage(indexpage);
  }
  return (
    <div className="row mb-5 mb-md-3">
      <div className="col-md-6 text-center text-md-left">
        <div>
          <span className="PaginationCountOfData">
            Showing {pagination_Options?.Pagination_Count} of{" "}
            {pagination_Options?.Pagination_Total} data
          </span>
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <Pagination className="float-right PaginationOptionsOfData my-0">
          <li className="page-item">
            <p
              className="page-link"
              role="button"
              tabindex="0"
              onClick={(y) => setInPage(1)}
            >
              <span aria-hidden="true">Previous</span>
            </p>
          </li>
          {pageNumber?.map((pageEle, index) => {
            return (
              <Pagination.Item
                key={index}
                onClick={(e) => handlePage(index + 1)}
                id="id_pagination_no"
                className={
                  inPage === index + 1 ? "activeButton" : "inactiveButton"
                }
              >
                {index + 1}
              </Pagination.Item>
            );
          })}

          <li className="page-item">
            <p
              className="page-link"
              role="button"
              tabindex="0"
              onClick={(x) => setInPage(pageNumber.length)}
            >
              <span aria-hidden="true">Next</span>
            </p>
          </li>
        </Pagination>
      </div>
    </div>
  );
}

export default Pagenation;
