import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import StyledNumberInput from './inputs/Number';
import { useResale } from '../../../../hooks/useMarketplace';
import { toast } from 'react-hot-toast';
import { pxToRem } from '../theme';
import { isEqual } from 'lodash';

const ResaleForm = ({ landData }) => {
  const { resale, makeAcceptOffer, setPrice } = useResale();

  const handleSubmit = (values) => {
    if (isEqual(values, initialValues)) {
      toast.error('Please change at least one value');
      return;
    }

    if (!landData?.is_offered_for_sale) {
      if (!+values.price) {
        toast.error('Please enter a price');
        return;
      }
      resale(landData?.token_id, values.price, values.listForSale, values.acceptOffers);
    } else {
      // * otherwise if the land is offered for sale, that means that the land is already listed for sale, so we need to check if the acceptOffers value is different from the initial value, and if it is different, we need to call the makeAcceptOffer function, and pass the token_id and the acceptOffers value.

      console.log('else values.price', values.price);
      if (values.acceptOffers !== initialValues.acceptOffers) {
        makeAcceptOffer(landData?.token_id, values.acceptOffers);
        console.log('here1');
      }
      // * and if the listForSale value is different from the initial value, or the price is different from the initial value, we need to call the setPrice function, and pass the token_id, the price, and the listForSale value.
      if (
        values.listForSale !== initialValues.listForSale ||
        values.price !== initialValues.price
      ) {
        console.log('here2');

        if (values.listForSale) {
          if (!+values.price) {
            toast.error('Please enter a price');
            return;
          }
          console.log('values.price', values.price);
          setPrice(landData?.token_id, values.price, true);
        } else {
          console.log('values.price', values.price);
          setPrice(landData?.token_id, 0, false);
        }
      }
    }
  };

  const initialValues = useMemo(
    () => ({
      acceptOffers: landData?.is_open_make_offer || false,
      listForSale: landData?.is_show_buy || false,
    }),
    [landData?.is_open_make_offer, landData?.is_show_buy],
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <div className='resale-form d-flex align-items-center gap-3 flex-wrap justify-content-between'>
            <div className='d-flex gap-4'>
              <FormControlLabel
                control={<Checkbox checked={values.acceptOffers} />}
                // value={false}
                label='Accept Offers'
                name='acceptOffers'
                onChange={handleChange}
                onBlur={handleBlur}
                className='m-0'
              />
              <FormControlLabel
                control={<Checkbox checked={values.acceptOffers || values.listForSale} />}
                // value={false}
                label='List For Sale'
                name='listForSale'
                onChange={handleChange}
                onBlur={handleBlur}
                className='m-0'
              />

              <StyledNumberInput
                name='price'
                value={values.price}
                placeholder='Price'
                onChange={(e) => {
                  const parsed = Number(e.target.value);

                  if (typeof parsed === 'number' && !Number.isNaN(parsed)) {
                    return handleChange(e);
                  }
                }}
              />
            </div>

            <Button
              type='submit'
              size='large'
              sx={{
                borderRadius: pxToRem(85),
                marginBottom: pxToRem(12),
                width: '20%',
                margin: '0',
              }}
              variant='contained'
              color='primary'>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResaleForm;
