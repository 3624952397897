import React from "react";
import { useUploadImage } from "../Hooks/useUploadImage";

function EditAvatarProfile(props) {
  //  this Component for display and edit avatar image | khaled Mofeed
  const { updateImage, userData } = useUploadImage("avatar");

  return (
    <div className="image-input imageCardLeftProfile" id="kt_profile_avatar">
      <div
        className="image-input-wrapper"
        id="id_bg_avatar_image2"
        style={{
          backgroundImage: `url(${userData?.avatar})`,
        }}
      ></div>
      <label
        className="btn btn-xs btn-icon btn-circle btn-black btn-hover-text-primary btn-shadow"
        data-action="change"
        data-toggle="tooltip"
        title=""
        data-original-title="Change avatar"
        id="id_lable_edit1"
      >
        <i className="fa fa-pen icon-sm text-white" id="id_icon_edit1"></i>
        <input
          type="file"
          name="profile_avatar"
          accept=".png, .jpg, .jpeg, .svg"
          id="id_input_file_lable1"
          onChange={updateImage}
        />
      </label>
    </div>
  );
}

export default EditAvatarProfile;
