import { Button, TextField, Typography } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import TextInput from './components/inputs/TextInput';
import Input from './components/inputs/Input';
import LandLatestOffers from './components/tables/LandLatestOffers';
import ImageUploader from './components/ImageUploader';
import { useParams } from 'react-router-dom';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import { pxToRem } from './theme';
import { useLandData } from '../../../hooks/useMarketplace';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ResaleForm from './components/SaleOptionsForm';

const EditLand = () => {
  const { id } = useParams();

  const { data, isLoading, error } = useLandData(id);

  const landData = data?.data?.data[0] || {};

  const [file, setFile] = useState(null);

  const handleChangeFile = ({ target: { files } }) => {
    setFile(files?.[0]);
  };

  const handleSubmit = (values) => {
// here were we shall handle the submit after the backend is ready
  };

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1); // Go back one page
  };

  const initialValues = useMemo(
    () => ({
      name: landData?.name || '',
      email: landData?.email || '',
      url: landData?.url || '',
      bio: landData?.bio || '',
      price: landData?.price || '',
    }),
    [isLoading],
  );

  return (
    <Container fluid>
      <Header />
      <Row className='justify-content-center DistributionVesting'>
        <Col md={12} xl={10} className='mb-0'>
          <Typography component='h5' variant='h5'>
            <svg
              onClick={handleNavigateBack}
              className='mr-2 cursor-pointer'
              width='19'
              height='13'
              viewBox='0 0 19 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M6.54549 0.686923C6.0044 0.187448 5.16085 0.221185 4.66138 0.762276L0.353684 5.42889C-0.117779 5.93964 -0.117784 6.72689 0.353674 7.23764L4.66137 11.9044C5.16084 12.4455 6.00438 12.4792 6.54548 11.9797C7.08658 11.4803 7.12032 10.6367 6.62086 10.0956L4.37871 7.6666L17.3334 7.6666C18.0698 7.6666 18.6667 7.06965 18.6667 6.33327C18.6667 5.59689 18.0698 4.99994 17.3334 4.99994L4.37876 4.99994L6.62084 2.57103C7.12032 2.02994 7.08658 1.1864 6.54549 0.686923Z'
                fill='#777E90'
              />
            </svg>
            Edit Land Profile
          </Typography>
          <hr className='mb-5' />
          <div
            style={{
              display: 'flex',
            }}>
            <div
              style={{
                flex: 1,
              }}>
              <ImageUploader src={landData.image} handleChange={handleChangeFile} file={file} />
            </div>
            <div style={{ flex: 2,display : "flex",flexDirection : "column", gap : "1rem" }}>
              <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, handleChange, handleBlur }) => (
                  <Form className='edit-land-form '>
                    <TextInput label='Land Name' fullWidth Input={Input} name='name' />
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem',
                      }}>
                      <TextInput label='Email:' fullWidth Input={Input} name='email' />

                      <TextInput label='Url:' fullWidth Input={Input} name='url' />
                    </div>
                    <TextInput
                      rows={4}
                      minRows={4}
                      label='Bio:'
                      fullWidth
                      Input={TextField}
                      style={{
                        fontSize: '14px',
                      }}
                      name='bio'
                    />
                    <Button
                      type='submit'
                      size='large'
                      sx={{
                        borderRadius: pxToRem(85),
                        marginBottom: pxToRem(12),
                      }}
                      fullWidth
                      variant='contained'
                      color='primary'>
                      Update Profile
                    </Button>
                  </Form>
                )}
              </Formik>
              <hr />
              <ResaleForm landData={landData} />
              <hr/>
              {landData?.token_id && <LandLatestOffers withEdit tokenId={landData?.token_id} />}
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default EditLand;
