import { useContext } from "react";
import toast from "react-hot-toast";
import axiosInstance, { request } from "../../../admin/plugins/axios";
import { AppContext } from "../../../scripts/context";
import { endpoints } from "../../../utils/networks";
/**
 * (Custom Hook)
 * this custom hook handles Change avatar or cover image
 * param args
 * returns {{updateImage,user}}
 * author Khaled Mofeed
 */
export const useUploadImage = (typeCahnge) => {
  const { userData, setUserData } = useContext(AppContext);
  const updateImage = (event) => {
    if (!event.target.files?.[0].type.includes("image"))
      return toast.error("Please upload an image");
    const file = event.target.files?.[0] || "";
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", typeCahnge);
    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
    request(
      endpoints.ProfileData.changeAvatarCover.method,
      endpoints.ProfileData.changeAvatarCover.url,
      formData
    )
      .then(({ data }) => {
        console.log("data", data.data.image_url);
        console.log("userData image", userData);
        if (typeCahnge === "avatar") {
          setUserData({ ...userData, avatar: data?.data?.image_url });
          toast.success(data?.message);
        } else {
          setUserData({ ...userData, cover: data?.data?.image_url });
          toast.success(data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.data.errors) {
          toast.error(err.response.data.errors.avatar[0]);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  return { updateImage, userData };
};
