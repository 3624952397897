export const locationArr = [
  {
    value: "A",
    name: "Section A",
  },
  {
    value: "B",
    name: "Section B",
  },
  {
    value: "C",
    name: "Section C",
  },
  {
    value: "D",
    name: "Section D",
  },
  {
    value: "E",
    name: "Section E",
  },
  {
    value: "F",
    name: "Section F",
  },
  {
    value: "G",
    name: "Section G",
  },
  {
    value: "H",
    name: "Section H",
  },
];
