import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AppContext } from "../../scripts/context";
import Footer from "./Footer";
import MainHeader from "./Header";
import UpdateNetWorkPage from "../../components/UpdateNetWorkPage";
import DisconnectedWallet from "../../components/DisconnectedWallet";
import ConnectWithOutToken from "../../components/ConnectWithOutToken";
import Connect from "../../components/Connect/index";
import { ConnectButton } from "@rainbow-me/rainbowkit";
/*  This Component Layout Used to show the desired screen based on the connection wallet status | khaled Mofeed */
const Layout = () => {
  const { userData, isToken, ethereum, disconnect } = useContext(AppContext);
  /*  This useEffect use for check if no account change make disconnect | khaled Mofeed */
  useEffect(() => {
    ethereum?.on("accountsChanged", async (account) => {
      if (account[0] !== userData.wallet_address) {
        disconnect();
      }
    });
  }, []);
  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");
          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Container fluid>
                      <MainHeader />
                      <DisconnectedWallet
                        openConnectModal={openConnectModal}
                        account={account}
                      />
                      <Footer />
                    </Container>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <Container fluid>
                      <MainHeader />
                      <UpdateNetWorkPage openChainModal={openChainModal} />
                      <Footer />
                    </Container>
                  );
                }
                if (connected && !isToken) {
                  return (
                    <>
                      <Connect
                        connected={connected}
                        address={account?.address}
                      />

                      <Container fluid>
                        <MainHeader />
                        <ConnectWithOutToken
                          openAccountModal={openAccountModal}
                          account={account}
                        />
                        <Footer />
                      </Container>
                    </>
                  );
                }

                return (
                  <>
                    <Outlet />
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
};

export default Layout;
