import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HTTP_IN_ACTIVE } from "../../../constants";
import { useTimeEvent } from "../Hooks/useTimeEvent";
import EventModal from "./EventModal";
import { Spinner } from "react-bootstrap";
import Pagenation from "./Pagination";
import { useAttendance } from "../Hooks/useAttendance";

function AllEvents(props) {
  /**
   * AllEvents, Component for view all the events in the system by fillterd
   * author Khaled Mofeed
   */
  const [modalShow, setModalShow] = useState(false);
  const [eventModal, setEventModal] = useState({});
  const { TimeEvent, AllEventByFillter } = useTimeEvent();
  const { loading, Attend } = useAttendance();
  /**
   * handleModalClick, function used for view the data of event selected by modal
   * author Khaled Mofeed
   */
  const handleModalClick = (e) => {
    setModalShow(true);
    const EventId = AllEventByFillter?.find(
      (AllEventDetails) => AllEventDetails?.id == e.target.id
    );
    setEventModal(EventId);
  };
  return (
    <>
      <Col md={8} xl={8} className="TrendingSection">
        <Row>
          {AllEventByFillter?.map((AllEventDetails) => (
            // TimeEvent(
            //   AllEventDetails.start_date,
            //   AllEventDetails.end_date
            // ) !== HTTP_IN_ACTIVE &&
            <Col
              md={6}
              xl={6}
              className="EventsCardsInFillter"
              Key={AllEventDetails?.id}
            >
              <div className="containerTrendAll">
                <Row>
                  <Col md={12}>
                    <img
                      src={AllEventDetails?.cover}
                      id={AllEventDetails?.id}
                      alt="TrendImgAll"
                      className="TrendImgAll w-100 pointer"
                      onClick={handleModalClick}
                    />
                    {AllEventDetails?.attendances_count ? (
                      <span className="LableSaleAll">
                        {" "}
                        + {AllEventDetails?.attendances_count}{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                    {TimeEvent(
                      AllEventDetails?.start_date,
                      AllEventDetails?.end_date,
                      AllEventDetails?.start_time
                    ) == "in 1 day" ? (
                      <span className="LableSaleAllWithe">In 1 Day</span>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Col
                        lg={12}
                        className="pt-4 pb-3 col-lg-12 px-35px pointer"
                      >
                        <Row>
                          <Col xs={6}>
                            <div className="SatetAt">
                              {" "}
                              {TimeEvent(
                                AllEventDetails?.start_date,
                                AllEventDetails?.end_date,
                                AllEventDetails?.start_time
                              )}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="actionLable">
                              <span className="lable-dark">
                                <img
                                  src="/img/events/location-light.svg"
                                  alt="location"
                                />{" "}
                                {AllEventDetails?.location.substring(0, 5) +
                                  "..."}
                              </span>
                              <span className="lable-LightBtn">
                                <img
                                  src="/img/events/LightBtn.svg"
                                  alt="location"
                                />
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} className="py-2 px-35px">
                        <div className="titleAndDesc">
                          <h4>{AllEventDetails?.name}</h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} className="pt-2 pb-5 px-35px">
                    <div className="ActionJump">
                      <button
                        disabled={AllEventDetails?.is_attended}
                        onClick={() => {
                          Attend(AllEventDetails?.id);
                        }}
                        className="btnCustome btn btn-outline-secondary w-100 mr-2"
                      >
                        {AllEventDetails?.is_attended
                          ? "You'r Attendance"
                          : "WANT TO GO"}
                        {loading && (
                          <Spinner
                            style={{ marginLeft: ".5rem" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                      <img
                        src="/img/events/share.svg"
                        alt="share"
                        className="shareBtn"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
        <Pagenation />
      </Col>

      <EventModal
        key={eventModal?.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        eventModal={eventModal}
        TimeEvent={TimeEvent}
        Attend={Attend}
        loading={loading}
      />
    </>
  );
}

export default AllEvents;
