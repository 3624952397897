import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Dropdown } from "react-bootstrap";
import Routes from "./Routes";
function RouteGenerator() {
  return (
    <>
      {Routes.map((route) => {
        return route.children ? (
          <Dropdown className="top-menue">
            <Dropdown.Toggle
              variant="outline-secondary"
              className="top-menue-title"
            >
              {route.header}
            </Dropdown.Toggle>

            <Dropdown.Menu className="DropdownMenu">
              {route.children.map(({ id, link, header }) => (
                <Nav key={id}>
                  <NavLink to={link} className="nav-link" key={id} end>
                    {header}
                  </NavLink>
                </Nav>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Nav key={route.link}>
            <NavLink
              to={route.link}
              className="nav-link"
              end
              key={route.header}
            >
              {route.header}
            </NavLink>
          </Nav>
        );
      })}
    </>
  );
}
export default RouteGenerator;
