import Modal from "react-modal";
import { customStyles, ModalContentStyle } from "./style";
import "./style.css";
import { useConnectWallet } from "../../../hooks/useConnectWallet";
import { useConnectMetaMask } from "../../../hooks/useConnectMetaMask";
/*  This Modal for Connect WalletModal | khaled Mofeed | 5/12/2022 */
export default function ConnectWalletModal({
  connectWalletModal,
  setConnectWalletModal,
}) {
  /* This imported of custome hook | khaled Mofeed | 5/12/2022 */
  const submitConnectWalletConnect = useConnectWallet({
    setConnectWalletModal,
  });
  const submitConnectMetaMask = useConnectMetaMask({ setConnectWalletModal });

  return (
    <Modal
      isOpen={connectWalletModal}
      onRequestClose={() => setConnectWalletModal(false)}
      style={customStyles}
      contentLabel="connect wallet modal"
      closeTimeoutMS={20}
      className="ConnectWalletModal"
    >
      <ModalContentStyle>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Connect Using ..
          </h5>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            style={{
              background: "transparent",
              border: "none",
              padding: 0,
              fontSize: "2rem",
              width: "36px",
            }}
            onClick={() => setConnectWalletModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="connect-wallet-modal-body">
          <button className="metamask" onClick={submitConnectMetaMask}>
            <img src="/img/metamask.webp" alt="commission-rate" />
            <span>MetaMask</span>
          </button>
          <button
            className="wallet-connect"
            onClick={submitConnectWalletConnect}
          >
            <img src="/img/walletconnect.png" alt="commission-rate" />
            <span>WalletConnect</span>
          </button>
        </div>
      </ModalContentStyle>
    </Modal>
  );
}
