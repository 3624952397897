import React from "react";
import TelvCoin from "../../../assets/home/marketplace/TelvCoin.png";
import VerificationIcon from "../../../assets/home/marketplace/Vector.svg";
import UserImg from "../../../assets/home/marketplace/user.png";
import { Card, Col, Image } from "react-bootstrap";
import { ADMIN } from "../../../constants";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SaveLocalStorag } from "../../../Helper/Helper";

//  this Component for view the data of NFT card | khaled Mofeed
const NftCard = ({ AllNftFillter, imgSrc }) => {
  //  this variables used for handle the price of Nft to view just 4 digits | khaled Mofeed
  let priceNum = AllNftFillter?.price;
  let priceNumStr = priceNum?.toString();
  let PRICE = priceNumStr?.substring(0, 4);

  // this function for navigate to Land Profile page | Nabil AbuShaban
  const navigate = useNavigate();
  const handleNavigateToLandProfile = () => {
    navigate(`/land/${AllNftFillter?.uuid}`);
    SaveLocalStorag("selectedHexagonId", AllNftFillter?.uuid);
  };

  return (
    <Col
      md={4}
      xl={3}
      onClick={handleNavigateToLandProfile}
      className="cursor-pointer"
    >
      <Card className="whole-card mb-3">
        <Card.Img variant="top" src={AllNftFillter?.image || imgSrc} fluid />
        <Card.Body>
          <Card.Title className="card-title">
            {AllNftFillter?.name || "VS-10-123G"}
          </Card.Title>
          <Card.Text>
            <div className="d-flex align-items-center mb-18">
              <div className="user-image">
                <Image
                  src={AllNftFillter?.seller?.avatar || UserImg}
                  alt="profile picture"
                  className="profilePicture"
                />
                <Image
                  className="verification-badge w-14"
                  src={VerificationIcon}
                  alt="verified"
                />
              </div>
              <div className="d-flex flex-column">
                <span className="card-owner">Owner</span>
                <span className="card-owner-name">
                  {AllNftFillter?.seller?.name || ADMIN}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="telv-symbol"
                  src={TelvCoin}
                  alt="TelvCoin Symbol"
                />
                {AllNftFillter?.price ? (
                  <>
                    {" "}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {AllNftFillter?.price} {AllNftFillter?.symbol}
                        </Tooltip>
                      }
                    >
                      <span className="card-price">
                        {PRICE} {AllNftFillter?.symbol}
                      </span>
                    </OverlayTrigger>
                  </>
                ) : (
                  <span className="card-price">
                    {PRICE} {AllNftFillter?.symbol}
                  </span>
                )}
              </div>
              <span className="place-bid-btn">Place Bid</span>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default NftCard;
