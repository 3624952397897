import './style.css';
import ValueImg from '../../../../assets/home/added-value.png';
import Dot from '../../../../assets/home/list-icon.png';
import { values } from '../../../../configs/landingValues';
import { Image, Row, Col } from 'react-bootstrap';

function value(props) {

  return (
    <Row className='elips'>
      <Col className='value-col' md={{ offset: 1, span: 10 }}>
        <Row className='add-value-title '>
          <Col className='text-center'>
            <span className='value-title'>Added Value</span>
          </Col>
        </Row>
        <Row className='value-content'>
          <Col className=' adjut-value-img' md={12} lg={5}>
            <Row className='value-img'>
              <Col className='text-center mob-add-val align-items-center justify-content-center'>
                <h1 className='value-title'>Add Value</h1>
              </Col>
              <Col>
                <Image src={ValueImg} alt='Value' fluid />
              </Col>
            </Row>
          </Col>
          <Col lg={{ offset: 1, span: 6 }}>
            <ul className='value-list'>
              {Object.keys(values).map((key, index) => {
                return (
                  <li className='value-list-item' key={index}>
                    <img src={Dot} alt='list-icon' className='list-icon' />
                    <div className='text-part'>
                      <h3 className='value-list-item-title'>{key}</h3>
                      <p className='value-list-item-text'>{values[key]}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default value;
