import React, { useContext } from "react";
import Card from "../components/Card";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { AppContext } from "../../../scripts/context";
import Pagenation from "./Pagination";
import SortMapLis from "./SortMapLis";
import { RemoveLocalStorag } from "../../../Helper/Helper";
function NftMarketPlace(props) {
  // this is the Container of MarketPlace Page components have:
  // SortMapLis, component for view the date by List or by Map
  // Card, Component for view the data of NFT card
  // Pagenation ,Component used for render the data from Api by pagination
  // | khaled Mofeed
  const { allNftFillter } = useContext(AppContext);
  const AllNftFillterData = allNftFillter.allNftFillter;
  // use it for remove it from local storage to unSelected any nft in map || khaled Mofeed
  RemoveLocalStorag("selectedHexagonId");
  return (
    <Col md={8} xl={9} className="TrendingSection">
      <Row className="marketplace-list ProfileCard">
        <SortMapLis />
        {AllNftFillterData?.map((AllNftFillter) => (
          <Card imgSrc="/img/card1.svg" AllNftFillter={AllNftFillter} />
        ))}
      </Row>
      <Pagenation />
    </Col>
  );
}

export default NftMarketPlace;
