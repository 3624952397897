import { ethers } from "ethers";
import abi from "../../../../configs/abis/revenue-contract.json";
import config from "../../../../configs";

const contractAddress = config.get("smartContract.REVENUE_ADDRESS");
let provider;
if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
}

// ^ Helper functions

// create a contract instance
const makeContract = async () => {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    return contract;
};

/**
* get revenue statistics data function from contract
* author Mahmoud Skaik
 */
export const getRevenueStatisticsData = async () => {
    const contract = await makeContract();
    var revenueData = await contract.getRevenueDataForAdmin();
    return {
        totalIncome: (parseInt(revenueData.totalIncome_) / 10 ** 16).toFixed(2),
        totalClaimed: (parseInt(revenueData.totalClaimed_) / 10 ** 16).toFixed(2),
        channelsCounter: parseInt(revenueData.channelsCounter_),
        revenuesCounter: parseInt(revenueData.revenuesCounter_),
    }
}

/**
* get channels list function from contract
* author Mahmoud Skaik
 */
export const getChannels = async () => {
    const contract = await makeContract();
    var channels = await contract.channelsList(0, 100);
    channels = await Promise.all(
        channels.channels_.map(async (item, index) => {
            let myItem = {
                id: parseInt(item.id),
                name: item.name,
                totalIncome: (parseInt(item.totalIncome) / 10 ** 16).toFixed(2),
                totalClaimed: (parseInt(item.totalClaimed) / 10 ** 16).toFixed(2),
            };
            return myItem;
        })
    );
    return channels;
}

/**
* get revenue list function from contract
* author Mahmoud Skaik
 */
export const getRevenues = async () => {
    const contract = await makeContract();
    var revenues = await contract.revenuesList(0, 100);
    revenues = await Promise.all(
        revenues.revenues_.map(async (item, index) => {
            let myItem = {
                id: parseInt(item.id),
                dateAt: new Date(parseInt(item.dateAt) * 1000).toLocaleString('en-GB').slice(0, 10),
                totalIncome: (parseInt(item.totalIncome) / 10 ** 16).toFixed(2),
                totalClaimed: (parseInt(item.totalClaimed) / 10 ** 16).toFixed(2),
                channels: item.channels.map((channelItem) => {
                    return {
                        id: channelItem.id,
                        name: channelItem.name,
                        totalIncome: channelItem.totalIncome
                    }
                })

            };
            return myItem;
        })
    );
    return revenues;
}

/**
* function to search on each revenue channel total incoming 
* author Mahmoud Skaik
 */
export const searchOnChannelInRevenues = (revenues, revenueIndex, channelId) => {
    for (var i = 0; i < revenues[revenueIndex].channels.length; i++) {
        if (parseInt(revenues[revenueIndex].channels[i].id) == channelId) {
            return (parseInt(revenues[revenueIndex].channels[i].totalIncome) / 10 ** 16).toFixed(2);
        }
    }
    return 0;
}

/**
* add new channel function from contract
* author Mahmoud Skaik
 */
export const createChannel = async (channelName) => {
    const contract = await makeContract();
    return await contract.addNewChannel(channelName)
}

/**
* add new revenue function from contract
* author Mahmoud Skaik
 */
export const addRevenue = async (revenueDate, channelIds, amounts) => {
    const contract = await makeContract();

    return await contract.addNewRevenue(revenueDate, channelIds, amounts)
}
