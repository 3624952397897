import * as yup from "yup";

export default yup.object().shape({
  first_name: yup
    .string()
    .required()
    .matches(/[^\s*].*[^\s*]/g, "First Name is a required"),
  last_name: yup
    .string()
    .required()
    .matches(/[^\s*].*[^\s*]/g, "Last Name is a required"),
  email: yup
    .string()
    .email()
    .required()
    .matches(/[^\s*].*[^\s*]/g, "Email is a required"),
  mobile: yup
    .string()
    .required("Please enter a valid mobile number")
    .min(8)
    .max(13),
  status: yup.boolean().required(),
});

export const filterSchema = yup.object().shape({
  full_name: yup.string(),
  email: yup.string().email(),
});
