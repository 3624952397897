/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { ethers } from "ethers";
import abi from "../configs/abis/nft-presale-contract.json";
import config from "../configs";
import { isNil } from "lodash";
import { toast } from "react-hot-toast";

const NftPresaleContext = createContext(null);
const contractAddress = config.get("smartContract.NFT_CONTRACT_ADDRESS");

export const NftPresaleProvider = ({ children }) => {
  const gasFees = 0.00278589; // ! block chain developer shall make a function for it

  const [contract, setContract] = useState(null);

  // metamask values
  const [startingDate, setStartingDate] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [maxAllocation, setMaxAllocation] = useState(null);
  const [userTotalPurchasedAmount, setUserTotalPurchasedAmount] =
    useState(null);
  const [price, setPrice] = useState(null);
  const [maxSupply, setMaxSupply] = useState(null);
  const [totalNFTPurchased, setTotalNFTPurchased] = useState(null);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(null);

  // own values
  const [NFT, setNFT] = useState(0);
  const [maxBuy, setMaxBuy] = useState(0);
  const [error, setError] = useState(null);
  const [minimum, setMinimun] = useState(1);
  const [refetch, setRefetch] = useState(false);

  const minAllocation = 1;

  const singleValueFunc = async (func) => {
    const value = await contract[func]();
    return parseInt(value);
  };

  const connectToContract = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    setContract(contract);
  };

  // get address
  const getAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const address = await signer.getAddress();
    return address;
  };

  //get balance
  const getBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const address = await getAddress();
    const balance = await provider.getBalance(address);
    return parseInt(balance / 10 ** 14) / 10000;
  };
  const init = async () => {
    if (!contract) return;
    const [
      start,
      end,
      max,
      purchasedAmount,
      nftPrice,
      totalNFTs,
      totalPurchased,
      address,
      balance,
    ] = await Promise.allSettled([
      singleValueFunc("getStartingDate"),
      singleValueFunc("getDeadlineDate"),
      singleValueFunc("getMaxAllocation"),
      singleValueFunc("getUserTotalNFTPurchasedAmount"),
      singleValueFunc("getNftPrice"),
      singleValueFunc("getMaxSupply"),
      singleValueFunc("getTotalNFTPurchased"),
      getAddress(),
      getBalance(),
    ]);

    setStartingDate(start.value);
    setDeadlineDate(end.value);
    setMaxAllocation(max.value);
    setUserTotalPurchasedAmount(purchasedAmount.value);
    setPrice(nftPrice.value / 10 ** 16);
    setMaxSupply(totalNFTs.value);
    setTotalNFTPurchased(totalPurchased.value);
    setAddress(address.value);
    setBalance(balance.value);
  };

  useEffect(() => {
    connectToContract();
  }, [window.ethereum]);

  useEffect(() => {
    init();
  }, [contract]);

  useEffect(() => {
    setMinimun(
      userTotalPurchasedAmount >= minAllocation
        ? 0
        : minAllocation - userTotalPurchasedAmount
    );
  }, [minAllocation, userTotalPurchasedAmount]);

  // check if NFT number is integer, if not then round it to the nearest integer
  const checkNFT = () => {
    if (NFT % 1 !== 0) {
      setNFT(Math.floor(NFT));
    }
    return value;
  };

  useEffect(() => {
    checkNFT();
  }, [NFT]);

  const balanceChange = async () => {
    const balance = await getBalance();
    setBalance(balance);
  };

  useEffect(() => {
    balanceChange();
  }, [refetch]);

  // check balance every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setRefetch(!refetch);
    }, 10000);
    return () => clearInterval(interval);
  }, [refetch]);

  const tableData = async (func, args, parameter) => {
    if (!contract) return;
    const { totalList_, ...rowData } = await contract[func](...args);
    const dataArr = rowData[parameter];

    const transactions = await Promise.all(
      dataArr.map(async (item, index) => {
        const myItem = {
          id: `${index}-${item.purchaseDate}`,
          amount: parseInt(item.NftCount),
          date: new Date(item.purchaseDate * 1000).toLocaleString([], {
            hour12: false,
          }),
        };
        return myItem;
      })
    );
    const totalList = parseInt(totalList_);
    return { transactions, totalList };
  };

  // this function is used to get the user's total transaction list
  const getUserPurchasedTransactionsList = async (page, limit) => {
    const args = [page, limit];
    const data = await tableData(
      "getUserPurchasedTransactionsList",
      args,
      "transactions_"
    );
    return data;
  };

  const ableToBuyLogic = () => {
    if (
      isNil(balance) ||
      isNil(minAllocation) ||
      isNil(userTotalPurchasedAmount) ||
      isNil(gasFees) ||
      isNil(maxAllocation)
    )
      return;

    if (balance < gasFees) {
      setError("Low BNB balance");
      return;
    }
    // here to check if the token is sold out
    if (userTotalPurchasedAmount >= maxAllocation) {
      setError("You have already purchased the maximum amount of TELV");
      return;
    }
    // here to check if the user has enough BNB balance
    const generalMax = Math.min(maxAllocation, maxSupply - totalNFTPurchased);
    const userMax = Math.min(
      generalMax,
      maxAllocation - userTotalPurchasedAmount
    );

    const ableToPurchase = Math.floor(
      Math.min((balance - gasFees) / price, userMax)
    );

    const amountToCheck =
      userTotalPurchasedAmount >= minAllocation
        ? 0
        : minAllocation - userTotalPurchasedAmount;

    ableToPurchase >= amountToCheck
      ? setMaxBuy(ableToPurchase)
      : setError("Insufficient BNB balance");
  };

  useEffect(() => {
    if (!maxBuy || !userTotalPurchasedAmount || !minAllocation) return;
    if (NFT < minimum) {
      setNFT(minimum);
    }
    if (NFT > maxBuy) {
      setNFT(maxBuy);
    }
  }, [NFT, maxBuy, minimum, userTotalPurchasedAmount, minAllocation]);

  const purchase = useCallback(
    async (e) => {
      console.log({ minimum });

      if (Math.ceil(userTotalPurchasedAmount) >= maxAllocation) {
        setError("You have already purchased the maximum amount of NFT");
        return;
      }
      if (+NFT < 1) {
        setError(`Minimum purchase amount is ${minimum} NFT`);
        return;
      }
      if (NFT > maxBuy) {
        setError(`Maximum purchase amount is ${maxBuy} NFT`);
        return;
      }
      let totalPay = NFT * price * 10 ** 18;
      totalPay = "0x" + Number(totalPay).toString(16);

      await contract.purchase("0x" + Number(NFT).toString(16), {
        value: totalPay,
      });
    },
    [NFT, maxBuy, price, userTotalPurchasedAmount, maxAllocation, contract]
  );

  // use useMemo to calculate the able to buy amount
  useEffect(() => {
    ableToBuyLogic();
  }, [contract, price, maxAllocation, totalNFTPurchased, maxSupply, balance]);

  const value = useMemo(
    () => ({
      NFT,
      setNFT,
      maxBuy,
      error,
      getUserPurchasedTransactionsList,
      purchase,
      balance,
      price,
      maxAllocation,
      totalNFTPurchased,
      userTotalPurchasedAmount,
      maxSupply,
      address,
      startingDate,
      deadlineDate,
    }),
    [
      NFT,
      maxBuy,
      error,
      getUserPurchasedTransactionsList,
      purchase,
      balance,
      price,
      maxAllocation,
      totalNFTPurchased,
      userTotalPurchasedAmount,
      maxSupply,
      address,
      startingDate,
      deadlineDate,
    ]
  );
  return (
    <NftPresaleContext.Provider value={value}>
      {children}
    </NftPresaleContext.Provider>
  );
};

export const useNftPresale = () => {
  return useContext(NftPresaleContext);
};
