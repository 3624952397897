import InfoCard from "./components/InfoCard";
import NFTActionsCard from "./components/NFTActionsCard";
import OwnerCard from "./components/OwnerCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { formatAccountAddress, useDebounce } from "../../../Helper/Helper";
import LandLatestOffers from "./components/tables/LandLatestOffers";
import PurchaseHistory from "./components/tables/PurchaseHistory";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import OfferModal from "./components/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { AppContext } from "../../../scripts/context";
import {
  useLandData,
  useMarketplaceOffer,
  useMarketplacePurchase,
} from "../../../hooks/useMarketplace";
import { toast } from "react-hot-toast";
import ContainerMap from "../components/Map/components/ContainerMap";

const MapImageContainer = styled("div")(() => ({
  width: "100%",
  height: "400px",
  position: "relative",
  marginBottom: "1.6rem",
}));

const InfoCardsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {},
}));

const InfoCoupleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "24px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const MainContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  padding: "0",
}));

const Section1 = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "1.5rem",
  // padding: "0",
  // [theme.breakpoints.up("lg")]: {
  //   padding: "0 109px",
  // },
}));

const TableWrapper = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const LandPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [needToRefetch, setNeedToRefetch] = useState(false);

  const {
    state: {
      user: { address: account },
    },
  } = useContext(AppContext);

  const { data, isLoading, error, refetch } = useLandData(id);
  if (error) {
    navigate("/marketplace");
  }

  const landData = data?.data?.data[0] || {};
  console.log("landData", landData);
  const pathToNavigate =
    pathname.split("")[pathname.split("").length - 1] === "/"
      ? pathname.slice(0, -1)
      : pathname;

  const handleEdit = () => {
    navigate(`${pathToNavigate}/edit`);
  };

  const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const openModal = () => {
    setIsOpen(true);
  };

  const {
    isBuyLoading,
    buyError,
    buyTxHash,
    buyTxReceipt,
    buyTxError,
    buyTxStatus,
    buy,
  } = useMarketplacePurchase();

  isBuyLoading && toast.loading("Transaction is pending...");
  buyTxStatus === "success" && toast.success("Transaction is successful");
  // buyTxStatus && toast.loading(buyTxStatus);
  buyTxError && toast.error(buyTxError);

  useEffect(() => {
    if (buyTxStatus === "success") {
      setTimeout(() => {
        refetch();
        setNeedToRefetch(true);
      }, 3000);
    }
  }, [buyTxStatus]);

  useEffect(() => {
    setTimeout(() => {
      toast.dismiss();
    }, 3000);
  }, [isBuyLoading]);

  const handleBuy = () => {
    buy(landData.token_id, landData.price);
  };

  const debouncedBuy = useDebounce(handleBuy, 1000);

  const isOwner = useMemo(() => {
    if (!account || !landData?.seller?.address) return true;
    return account === landData?.seller?.address;
  }, [account, landData?.seller?.address]);
  const {
    isOfferLoading,
    offerError,
    offerTxHash,
    offerTxReceipt,
    offerTxError,
    offerTxStatus,
    offer,
  } = useMarketplaceOffer();

  isOfferLoading && toast.loading("Transaction is pending...");
  offerTxStatus === "success" && toast.success("Transaction is successful");
  // offerTxStatus && toast.loading(offerTxStatus);
  offerTxError && toast.error(offerTxError);

  useEffect(() => {
    let timer;
    if (offerTxStatus === "success") {
      timer = setTimeout(() => {
        setNeedToRefetch(true);
      }, 3000);
    }
    return () => timer && clearTimeout(timer);
  }, [offerTxStatus]);

  useEffect(() => {
    setTimeout(() => {
      toast.dismiss();
    }, 3000);

    return () => toast.dismiss();
  }, [isOfferLoading]);

  // return needToRfresh to false after 3 seconds
  useEffect(() => {
    let timer;
    if (needToRefetch) {
      timer = setTimeout(() => {
        setNeedToRefetch(false);
      }, 3000);
    }

    return () => timer && clearTimeout(timer);
  }, [needToRefetch]);

  return (
    <>
      <Container fluid>
        <Header />
        <Row className="justify-content-center DistributionVesting">
          <Col md={12} xl={10} className="mb-0 LandProfileMap">
            <ContainerMap IdSelected={id} />

            <MainContent>
              <Section1>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <OwnerCard
                    isOwner={isOwner}
                    owner={landData?.seller}
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    onEdit={handleEdit}
                    onClick={() => {}}
                    onJump={() => {}}
                  />
                  {isDownMd && (
                    <NFTActionsCard
                      sx={{
                        height: "100%",
                        flex: 1,
                        width: "100%",
                        marginBottom: "1.5rem",
                      }}
                      price={Number(landData.price)}
                      onBuy={debouncedBuy}
                      onClick={openModal}
                    />
                  )}
                  <InfoCardsContainer
                    sx={{
                      display: "flex",
                      gap: "24px",
                    }}
                  >
                    <InfoCoupleContainer
                      style={{
                        display: "flex",
                        gap: "24px",
                      }}
                    >
                      <InfoCard
                        sx={{
                          flex: 1,
                        }}
                        clipboardCopy
                        title="Owner Address"
                        value={landData?.seller?.address}
                        formatedValue={formatAccountAddress(
                          landData?.seller?.address || ""
                        )}
                      />
                      <InfoCard
                        sx={{
                          flex: 1,
                        }}
                        title="Location"
                        value={landData.location}
                      />
                    </InfoCoupleContainer>
                    <InfoCoupleContainer
                      style={{
                        display: "flex",
                        gap: "24px",
                      }}
                    >
                      <InfoCard
                        sx={{
                          flex: 1,
                        }}
                        title="Email"
                        value="TBD@gmail.com"
                      />

                      <InfoCard
                        sx={{
                          flex: 1,
                        }}
                        title="Url"
                        value={
                          landData.url ? landData.url : "www.metachain.com"
                        }
                      />
                    </InfoCoupleContainer>
                  </InfoCardsContainer>
                </div>
                {!isDownMd &&
                  !isOwner &&
                  !(!landData.is_show_buy && !landData.is_open_make_offer) && (
                    <NFTActionsCard
                      sx={{
                        height: "100%",
                        flex: 1,
                      }}
                      price={landData.price}
                      onClick={openModal}
                      onBuy={debouncedBuy}
                      isShowBuy={landData.is_show_buy}
                      isShowMakeOffer={landData.is_open_make_offer}
                    />
                  )}
              </Section1>
              <InfoCard
                title="Bio"
                value={landData.bio}
                sx={{
                  justifyContent: "start",
                }}
              />
              {landData?.token_id && (
                <div
                  className="flex-md-nowrap flex-wrap"
                  style={{
                    display: "flex",
                    // justifyContent: 'space-around',
                    gap: "1.5rem",
                  }}
                >
                  <TableWrapper>
                    <PurchaseHistory
                      needToRefetch={needToRefetch}
                      tokenId={landData?.token_id}
                    />
                  </TableWrapper>
                  <TableWrapper>
                    <LandLatestOffers
                      needToRefetch={needToRefetch}
                      tokenId={landData?.token_id}
                      owner={landData?.seller?.address === account}
                    />
                  </TableWrapper>
                </div>
              )}
            </MainContent>
          </Col>
        </Row>
        <Footer />
      </Container>

      {landData?.token_id && (
        <OfferModal
          key={landData?.token_id}
          show={isOpen}
          onHide={() => setIsOpen(false)}
          data={landData}
          offer={offer}
        />
      )}
    </>
  );
};

export default LandPage;
