/**
 * use yup schema to validator object which contains the error messages
 * author Khaled Mofeed
 */
import * as yup from "yup";
export default yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  "g-recaptcha-response": yup
    .string()
    .nullable(true)
    .required("You must check the reCAPTCHA."),
});
